import React, { useEffect, useState, useRef } from "react";
import "./ScrollBar.css";
import styled, { keyframes } from "styled-components";

const ScrollGrayBar = ({ $currentImageIndex, barIndex }) => {
  const $ishalflength = $currentImageIndex === barIndex - 1;
  const $istransparent = $currentImageIndex === barIndex + 1;

  return (
    <ScrollBarDiv
      $ishalflength={$ishalflength}
      $istransparent={$istransparent}
      $currentImageIndex={$currentImageIndex}
    />
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
`;

const ScrollBarDiv = styled.div`
  display: inline-block;
  min-width: 58px;
  height: ${({ $ishalflength }) => ($ishalflength ? "9vh" : "70vh")};
  opacity: ${({ $istransparent }) => ($istransparent ? 0 : 1)};

  margin-right: 20px;

  background-color: #ffffff;
  opacity: 0.2;

  transition: 0.5s all;

  transform: ${({ $currentImageIndex }) => {
    return `translateX(${-Number($currentImageIndex) * 78}px)`;
  }};

  animation: ${fadeIn} 1s forwards;

  @media (max-width: 1850px) {
    min-width: 36px;
    margin-right: 15px;

    transform: ${({ $currentImageIndex }) => {
      return `translateX(${-Number($currentImageIndex) * 51}px)`;
    }};
  }

  @media (max-width: 786px) {
    min-width: 30px;
    margin-right: 12px;

    transform: ${({ $currentImageIndex }) => {
      return `translateX(${-Number($currentImageIndex) * 42}px)`;
    }};
  }
`;

const ScrollBar = ({ onHover, $currentImageIndex, totalImages }) => {
  // // isHovered는 마우스가 스크롤바 위에 있는지를 나타냄
  const [isHovered, setIsHovered] = useState(false);

  const footerRef = useRef(null);

  // // 마우스가 scrollFooter에 진입하거나 나갈 때 isHovered 상태를 업데이트
  const handleFooterMouseEnter = () => {
    setIsHovered(true);
    onHover(true);
  };

  const handleFooterMouseLeave = () => {
    setIsHovered(false);
    onHover(false);
  };

  const GrayBars = [...Array(totalImages + 5)].map((_, index) => (
    <ScrollGrayBar
      key={"GrayBar" + index}
      barIndex={index}
      $currentImageIndex={$currentImageIndex}
    />
  ));

  return (
    <>
      <ScrollBarWrapper>{GrayBars}</ScrollBarWrapper>
      <div
        className={`scrollFooter ${isHovered ? "up" : ""}`}
        onMouseEnter={handleFooterMouseEnter}
        onMouseLeave={handleFooterMouseLeave}
        ref={footerRef}
      >
        <ScrollBarFooterTxt>
          <div className='scrollFooter-main'>
            <p>We explore and test</p>
            <p>reasonable standards.</p>
          </div>
          <div className='scrollFooter-sub'>
            <p>1F, 8, Huam-ro 16-gil, Yongsan-gu</p>
            <p>Seoul, Republic of Korea</p>
            <p>© 2023 by boundary. All rights reserved.</p>
          </div>
        </ScrollBarFooterTxt>
      </div>
    </>
  );
};

export default ScrollBar;

const ScrollBarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 390px;
  margin-top: 138px;
  margin-left: 20px;

  overflow: hidden;
  /* white-space: nowrap; */

  /* flex-direction: row-reverse; */

  @media (max-width: 1850px) {
    width: 255px;
    margin-top: 87px;
    margin-left: 35px;
  }

  @media (max-width: 786px) {
    width: calc(100vw - 280px);
    margin-left: 18px;
  }
`;

const ScrollBarFooterTxt = styled.span`
  animation: ${fadeIn} 1s;

  .scrollFooter-main {
    font: normal normal 600 24px/32px Poppins;
    letter-spacing: -0.24px;
    color: #ffffff;
    opacity: 1;

    padding-bottom: 58px;

    border-bottom: 0.5px solid #fff;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 600 16px/28px Poppins;
      letter-spacing: -0.16px;
      color: #ffffff;
      opacity: 1;

      padding-bottom: 30px;
    }
  }

  .scrollFooter-sub {
    text-align: left;
    font: normal normal normal 16px/28px Poppins;
    letter-spacing: -0.24px;
    color: #666666;
    opacity: 1;

    padding-top: 40px;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal normal 12px/20px Poppins;
      letter-spacing: -0.18px;
      color: #666666;
      opacity: 1;
    }
  }
`;
