import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-web";
import animationData from "../../assets/mobileMenuButton.json";
import "./MobileMenu.css";
import { NavLink, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock-upgrade";

const MobileMenuButton = () => {
  const containerRef = useRef(null);
  const [isHovered, setHovered] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const animation = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    animation.current = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: animationData,
    });

    return () => {
      animation.current.destroy();
    };
  }, []);

  const body = document.querySelector("body");

  useEffect(() => {
    if (window.innerWidth < 786) {
      if (isMenuOpen) {
        disableBodyScroll(body);
      } else {
        // 모달이 닫혔을 때 스크롤 잠금을 해제합니다.
        clearAllBodyScrollLocks();
      }
    }

    // 컴포넌트가 언마운트될 때 잠금을 해제합니다. (선택적)
    return () => clearAllBodyScrollLocks();
  }, [isMenuOpen]);

  const handleClick = () => {
    if (isMenuOpen) {
      // 메뉴가 열려있는 경우, 닫도록 합니다.
      setMenuOpen(false);
      animation.current.setDirection(-1);
      animation.current.goToAndPlay(animation.current.totalFrames - 1, true);
    } else {
      // 메뉴가 닫혀있는 경우, 열도록 합니다.
      setMenuOpen(true);
      animation.current.setDirection(1);
      animation.current.goToAndPlay(0, true);
    }
  };

  return (
    <div className='mobileMenuContainer'>
      <div
        className={`mobileMenuButton ${isMenuOpen ? "open" : ""}`}
        onClick={handleClick}
      >
        <div
          className='mainButton'
          ref={containerRef}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </div>
      <div className='menuContent'>
        <NavLink to='/about' onClick={handleClick}>
          <p>About</p>
        </NavLink>
        <NavLink to='/work' onClick={handleClick}>
          <p>Work</p>
        </NavLink>
        <NavLink to='/contact' onClick={handleClick}>
          <p>Contact</p>
        </NavLink>
      </div>
      <Backdrop onClick={handleClick} $isOpen={isMenuOpen} />
    </div>
  );
};

export default MobileMenuButton;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: ${(props) => (props.$isOpen ? "block" : "none")};

  width: 100vw;
  height: 100vh;

  z-index: 5;
`;
