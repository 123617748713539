import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { styled } from "styled-components";
import { infoAgree, requestFile, requestState } from "../../store/requestAtom";
import { useEffect, useState } from "react";
import { requestProject } from "../../api/project-request";
import { isLoadingState } from "../../store/isLoading";
import { ContactAtom } from "../../store/modalAtom";

const MobileRequestNav = () => {
  const requestRecoilState = useRecoilValue(requestState);
  const requestFileData = useRecoilValue(requestFile);
  const infoAgreeState = useRecoilValue(infoAgree);
  const resetRequestState = useResetRecoilState(requestState);
  const resetRequestFileData = useResetRecoilState(requestFile);
  const resetInfoAgreeState = useResetRecoilState(infoAgree);
  const location = useLocation();
  const [nextState, setNextState] = useState(false);
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(isLoadingState);

  const [isContactModalOpen, setIsContactModalOpen] =
    useRecoilState(ContactAtom);

  useEffect(() => {
    const locationValue = location.pathname.split("/")[3];
    switch (locationValue) {
      case "1":
        if (requestRecoilState.projectRequestType.length !== 0) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "2":
        if (
          requestRecoilState.projectName !== "" &&
          requestRecoilState.projectDetail !== "" &&
          requestRecoilState.projectPreparationStatus.length !== 0 &&
          requestFileData !== null
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "3":
        if (
          requestRecoilState.projectPeriod !== "" &&
          requestRecoilState.projectBudget !== "" &&
          requestRecoilState.startingPoint !== "" &&
          requestRecoilState.contactPoint !== ""
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "4":
        if (
          requestRecoilState.managerNameAndPosition !== "" &&
          requestRecoilState.clientName !== "" &&
          requestRecoilState.email !== "" &&
          requestRecoilState.contact !== "" &&
          requestRecoilState.siteName !== "" &&
          infoAgreeState
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
    }
  }, [location, requestRecoilState, infoAgreeState, requestFileData]);

  const requestPostHandler = async () => {
    const data = {
      clientName: requestRecoilState.clientName,
      contact: requestRecoilState.contact,
      contractPoint: requestRecoilState.contactPoint,
      email: requestRecoilState.email,
      files: requestFileData,
      managerNameAndPosition: requestRecoilState.managerNameAndPosition,
      projectBudget: requestRecoilState.projectBudget,
      projectDetail: requestRecoilState.projectDetail,
      projectName: requestRecoilState.projectName,
      projectPeriod: requestRecoilState.projectPeriod,
      projectPreparationStatus: requestRecoilState.projectPreparationStatus.map(
        (item) => item.replace("• ", "")
      ),
      projectRequestType: requestRecoilState.projectRequestType.map((item) =>
        item.replace("• ", "")
      ),
      siteName: requestRecoilState.siteName,
      startingPoint: requestRecoilState.startingPoint,
    };

    const formData = new FormData();

    for (const key in data) {
      if (key !== "files") {
        formData.append(key, data[key]);
      }
    }

    if (data.files) {
      formData.append("files", data.files);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const response = await requestProject(data);

    if (response.isSuccess) {
      setIsLoading(false);
      resetRequestState();
      resetRequestFileData();
      resetInfoAgreeState();
      setIsContactModalOpen(true);
      navigate("/contact");
    } else {
      alert("전송실패");
    }
  };

  return (
    <MobileRequestNavStyle index={location.pathname.split("/")[3]}>
      <div className='request-nav'>
        {location.pathname.split("/")[3] === "1" ? (
          <Link to={"/contact"}>
            <img src={process.env.PUBLIC_URL + "/img/contactX.svg"} />
          </Link>
        ) : (
          <Link
            to={`/contact/request/${
              Number(location.pathname.split("/")[3]) - 1
            }`}
          >
            <img
              className='arrow'
              src={process.env.PUBLIC_URL + "/img/contactLeftArrow.svg"}
            />
          </Link>
        )}
        {location.pathname.split("/")[3] !== "4" ? (
          <Link
            to={`/contact/request/${
              Number(location.pathname.split("/")[3]) + 1
            }`}
            id='next-button'
            className={!nextState && "disabled"}
          >
            <img
              className='arrow'
              src={process.env.PUBLIC_URL + "/img/contactRightArrow.svg"}
            />
          </Link>
        ) : (
          <a
            id='next-button'
            className={!nextState && "disabled"}
            onClick={requestPostHandler}
          >
            <img
              className='arrow'
              src={process.env.PUBLIC_URL + "/img/contactRightArrow.svg"}
            />
          </a>
        )}
      </div>
    </MobileRequestNavStyle>
  );
};

export default MobileRequestNav;

const MobileRequestNavStyle = styled.div`
  position: relative;
  display: none;

  flex-direction: row;

  height: 42px;
  margin-bottom: 86px;

  color: #ffffff;

  @media (max-width: 786px) {
    display: flex;
    margin-top: 36px;
    margin-bottom: 80px;
  }

  .request-nav {
    position: absolute;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 36px);

    margin: 0px 18px;
    img {
      width: 42px;
      height: 42px;

      &.arrow {
        height: 42px;
        width: 58px;
      }
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  #next-button {
    position: relative;

    &::after {
      display: ${(props) => {
        return props.index == 4 ? "inherit" : "none";
      }};
      content: "Project Request";
      position: absolute;
      bottom: -50px; /* 이미지 아래로 글자를 조정하세요 */
      left: -130px;
      width: 200px;
      text-align: right;
      font: normal normal 600 18px/56px Poppins;
      letter-spacing: -0.36px;
      color: #ffffff;
      opacity: 1;

      @media (max-width: 1850px) {
        bottom: -40px;
        left: -150px;

        text-align: right;
        font: normal normal 600 12px/56px Poppins;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }
      @media (max-width: 768px) {
        bottom: -40px;
        left: -43px;
        width: 100px;

        text-align: right;
        font: normal normal 600 12px/56px Poppins;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
`;
