export const requestProject = async (data) => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const response = await fetch(
      "https://api.admin.boundary.team/api/user/project-request",
      {
        method: "post",
        body: formData,
      }
    );

    if (!response.ok) {
      return { isSuccess: false };
    }

    return { isSuccess: true };
  } catch (error) {
    return { isSuccess: false };
  }
};
export const address = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/address",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data, isSuccess: true };
  } catch (error) {
    return { isSuccess: false };
  }
};
export const policy = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/privacy-policy",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data };
  } catch (error) {
    return { isSuccess: false };
  }
};

export const projectProcess = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/project-process",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data };
  } catch (error) {
    return { isSuccess: false };
  }
};
// export const companyProfile = async () => {
//   try {
//     const response = await fetch(
//       "https://api.admin.boundary.team/api/user/company-profile",
//       {
//         method: "get",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     const data = await response.json();

//     if (!response.ok || !data.success) {
//       return { isSuccess: false };
//     }

//     return { result: data, isSuccess: true };
//   } catch (error) {
//     return { isSuccess: false };
//   }
// };

export const companyProfile = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/company-profile",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    // Return the data without downloading the file
    return { result: data.data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};

export const service = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/service",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.dataList, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const partner = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/partner",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.dataList, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const background = async (backgroundType) => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/background?backgroundType=${backgroundType}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.dataList, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const thumbnail = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/project/thumbnail/list",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.dataList, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const workProject = async () => {
  try {
    const response = await fetch(
      "https://api.admin.boundary.team/api/user/project",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.dataList, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const workProjectId = async (id) => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/project/${id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok || !data.success) {
      return { isSuccess: false };
    }

    return { result: data.data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const requestType = async () => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/project-request/type`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return { isSuccess: false };
    }

    return { result: data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const requestStatus = async () => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/project-request/status`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return { isSuccess: false };
    }

    return { result: data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const requestPeriod = async () => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/project-request/period`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return { isSuccess: false };
    }

    return { result: data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
export const requestBudget = async () => {
  try {
    const response = await fetch(
      `https://api.admin.boundary.team/api/user/project-request/budget`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return { isSuccess: false };
    }

    return { result: data, isSuccess: true };
  } catch (error) {
    console.error(error);
    return { isSuccess: false };
  }
};
