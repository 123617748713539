import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ file: fileProp }) {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const viewerRef = useRef();
  const { search } = useLocation();
  const file = new URLSearchParams(search).get("file");
  useEffect(() => {
    if (!file) return;
    const loadingTask = pdfjs.getDocument(file);
    loadingTask.promise
      .then((pdf) => {
        setNumPages(pdf.numPages);
      })
      .catch((error) => {
        console.error("Failed to load PDF file:", error);
      });
  }, [file]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div ref={viewerRef} className="pdfViewer">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={index + 1}
            pageNumber={index + 1}
            className="pdfPage"
            width={viewerRef.current?.clientWidth}
            disableTextLayer={true} // 텍스트 레이어 비활성화
          />
        ))}
      </Document>
    </div>
  );
}

export default PDFViewer;
