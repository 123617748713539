import { useRecoilValue } from "recoil";
import { requestState } from "../../store/requestAtom";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";

const RequestSideBar = () => {
  const requestRecoilState = useRecoilValue(requestState);
  const location = useLocation();

  const TypeList = requestRecoilState.projectRequestType.map((type, idx) => {
    return <li key={"typeopiton" + idx}>{type}</li>;
  });

  const PreparationList = requestRecoilState.projectPreparationStatus.map(
    (Preparation, idx) => {
      return <li key={"PreparationOption" + idx}>{Preparation}</li>;
    }
  );

  return (
    <SideBarStyle>
      <div className='selected-option'>
        {requestRecoilState.projectPeriod ||
        requestRecoilState.projectBudget ? (
          <div
            className={`option not-bottom ${
              location.pathname.split("/")[3] == 3 ? "located" : ""
            }`}
          >
            <ul>
              <li>
                {requestRecoilState.projectPeriod +
                  " / " +
                  requestRecoilState.projectBudget}
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        {requestRecoilState.projectPreparationStatus.length !== 0 ? (
          <div
            className={`option not-bottom ${
              location.pathname.split("/")[3] == 2 ? "located" : ""
            }`}
          >
            <ul>{PreparationList}</ul>
          </div>
        ) : (
          <></>
        )}

        <div
          className={`option ${
            location.pathname.split("/")[3] == 1 ? "located" : ""
          }`}
        >
          {requestRecoilState.projectRequestType.length === 0 ? (
            <span>프로젝트 분야</span>
          ) : (
            <ul>{TypeList}</ul>
          )}
        </div>
        <div className='default-option'>
          <span>프로젝트를 의뢰하고 싶어요.</span>
        </div>
      </div>
    </SideBarStyle>
  );
};

export default RequestSideBar;

const SideBarStyle = styled.div`
  .not-bottom {
    border-bottom: 1px solid #707070;
  }

  .selected-option {
    width: 404px;
    flex-shrink: 0;

    padding-left: 34px;
    margin-right: 98px;
    .option {
      text-align: left;
      font: normal normal 500 24px/56px Pretendard;
      letter-spacing: -0.48px;
      color: #707070;
      opacity: 1;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        text-align: left;
        font: normal normal 500 24px/56px Pretendard;
        letter-spacing: -0.48px;
        color: #707070;
        opacity: 1;

        li {
          display: flex;
          align-items: center;
          margin-right: 16px;

          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 12px;
            background: #707070;
            border-radius: 50%;
          }
        }
      }
    }
    .default-option {
      text-align: left;
      font: normal normal 500 20px/30px Pretendard;
      letter-spacing: -0.4px;
      color: #707070;
      opacity: 1;
    }

    @media (max-width: 1850px) {
      width: 275px;
      padding-left: 35px;
      margin-right: 66px;
      .option {
        text-align: left;
        font: normal normal 500 18px/30px Pretendard;
        letter-spacing: -0.36px;
        color: #707070;
        opacity: 1;

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          text-align: left;
          font: normal normal 500 18px/30px Pretendard;
          letter-spacing: -0.36px;
          color: #707070;
          opacity: 1;

          li {
            display: flex;
            align-items: center;
            margin-right: 16px;

            &::before {
              content: "";
              display: inline-block;
              width: 5px;
              height: 5px;
              margin-right: 8px;
              background: #707070;
              border-radius: 50%;
            }
          }
        }
      }
      .default-option {
        text-align: left;
        font: normal normal 500 14px/56px Pretendard;
        letter-spacing: -0.28px;
        color: #707070;
        opacity: 1;
      }
    }

    @media (max-width: 786px) {
      display: none;
    }
  }
  .located {
    &.not-bottom {
        border-bottom: 1px solid #ffffff;
    }
    ul {
      color: #ffffff !important;

      li {
        &::before {
          background: #ffffff !important;
        }
      }
    }
  }
`;
