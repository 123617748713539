import { styled } from "styled-components";
import CurationButton from "./CurationButton";
import { useLocation } from "react-router-dom";
import DesktopMenu from "./DesktopMenu";
import MobileMenuButton from "./MobileMenu";

const NavBar = () => {
  const path = useLocation();

  return (
    <NavBarNav>
      {path.pathname === "/" ? (
        <CurationButton />
      ) : (
        <>
          <DesktopMenu />
          <MobileMenuButton />
        </>
      )}
    </NavBarNav>
  );
};

export default NavBar;

const NavBarNav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;

  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;

  a {
    text-decoration: none;
    color: #000;

    &.active {
    }
  }
`;
