import React, { useEffect, useState, useRef } from "react";
import { address } from "../../api/project-request";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { footerHeightAtom } from "../../store/footerHeight";
import { throttle } from "lodash";
import Salse from '../../assets/salse.png';

const Footer = () => {
  const [data, setData] = useState(null);
  const [visibleHeight, setVisibleHeight] = useRecoilState(footerHeightAtom); // 추가: 화면에 보이는 푸터의 높이를 저장하는 상태

  const footerRef = useRef(null); // 추가: 푸터 요소에 대한 참조

  const location = useLocation();
  const isDetailPage = location.pathname === "/detail";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await address();
        setData(response.result.data);
      } catch (error) {
        setData("서버오류");
      }
    };

    fetchData();
  }, []);

  // 추가: Intersection Observer를 설정합니다.
  useEffect(() => {
    if (!footerRef.current) return;

    const throttledObserverCallback = throttle((entries) => {
      entries.forEach((entry) => {
        const visibleRatio = entry.intersectionRatio;
        const currentHeight = footerRef.current.clientHeight * visibleRatio;
        setVisibleHeight(currentHeight);
      });
    }, 100);

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: Array.from({ length: 101 }, (_, index) => index * 0.01),
    };

    const observer = new IntersectionObserver(
      throttledObserverCallback,
      observerOptions
    );
    observer.observe(footerRef.current);

    return () => observer.disconnect(); // 정리 함수: 컴포넌트가 언마운트되면 observer를 해제합니다.
  }, [footerRef]);

  return (
    <FooterStyle>
      <footer
        ref={footerRef}
        className={isDetailPage ? "detail-footer" : "footerWrapper"}
      >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <div>
            {data && (
              <>
                <FooterTxt>{data.address}</FooterTxt>
              </>
            )}
            <FooterSubTxt>Copyright by boundary All rights reserved.</FooterSubTxt>
          </div>
          <img src={Salse} alt="" style={{width: 90}} />
        </div>
      </footer>
    </FooterStyle>
  );
};

export default Footer;

const FooterStyle = styled.div`
  .footerWrapper {
    position: sticky;
    display: relative;
    bottom: 0;
    width: 100%;

    color: #626262;
    background-color: #000;
    padding: 46px 34px;
    float: left;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 50%;
      width: calc(50% - 34px);
      border-top: 1px solid #262626;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 50%;
      width: 0%;
      width: calc(50% - 34px);
      border-top: 1px solid #262626;
    }
  }

  .detail-footer {
    color: #fff;
    padding: 20px;
    float: left;
  }

  @media (max-width: 1850px) {
    .footerWrapper {
      width: 100%;
      height: 146px;
      padding-top: 29px;
      padding-bottom: 51px;
      padding-left: 35px;
      padding-right: 35px;
      &::before {
        width: calc(50% - 35px);
      }
      &::after {
        width: calc(50% - 35px);
      }
    }
  }

  @media (max-width: 768px) {
    .footerWrapper {
      width: 100%;
      height: 146px;
      padding-top: 19px;
      padding-bottom: 47px;
      padding-left: 20px;
      padding-right: 20px;
      &::before {
        width: calc(50% - 20px);
      }
      &::after {
        width: calc(50% - 20px);
      }
    }
  }
`;

const FooterTxt = styled.p`
  text-align: left;
  font: normal normal 500 16px/32px Poppins;
  letter-spacing: -0.32px;
  color: #626262;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 500 12px/32px Poppins;
    letter-spacing: -0.24px;
    color: #626262;
    opacity: 1;
  }

  @media (max-width: 786px) {
    text-align: left;
    font: normal normal 500 14px/22px Poppins;
    letter-spacing: -0.28px;
    color: #626262;
    opacity: 1;

    margin-bottom: 15px;
  }
`;

const FooterSubTxt = styled.p`
  text-align: left;
  font: normal normal 500 16px/32px Poppins;
  letter-spacing: -0.32px;
  color: #626262;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 500 12px/32px Poppins;
    letter-spacing: -0.24px;
    color: #626262;
    opacity: 1;
  }

  @media (max-width: 786px) {
    text-align: left;
    font: normal normal 500 12px/22px Poppins;
    letter-spacing: -0.24px;
    color: #626262;
    opacity: 1;
  }
`;
