import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { styled } from "styled-components";
import { infoAgree, requestFile, requestState } from "../../store/requestAtom";
import { useEffect, useState } from "react";
import { requestProject } from "../../api/project-request";
import { ContactAtom } from "../../store/modalAtom";
import { isLoadingState } from "../../store/isLoading";

const RequestTitleArray = {
  1: "프로젝트 분야를 선택해주세요.",
  2: "프로젝트 주요 정보를 알려주세요.",
  3: "프로젝트 기간과 예산을 선택해주세요.",
  4: "의뢰하시는 분의 정보를 입력해주세요.",
};

const RequestHeader = () => {
  const requestRecoilState = useRecoilValue(requestState);
  const requestFileData = useRecoilValue(requestFile);
  const infoAgreeState = useRecoilValue(infoAgree);
  const resetRequestState = useResetRecoilState(requestState);
  const resetRequestFileData = useResetRecoilState(requestFile);
  const resetInfoAgreeState = useResetRecoilState(infoAgree);
  const location = useLocation();
  const [nextState, setNextState] = useState(false);
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(isLoadingState);

  const [isContactModalOpen, setIsContactModalOpen] =
    useRecoilState(ContactAtom);

  useEffect(() => {
    const locationValue = location.pathname.split("/")[3];
    switch (locationValue) {
      case "1":
        if (requestRecoilState.projectRequestType.length !== 0) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "2":
        if (
          requestRecoilState.projectName !== "" &&
          requestRecoilState.projectDetail !== "" &&
          requestRecoilState.projectPreparationStatus.length !== 0 &&
          requestFileData !== null
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "3":
        if (
          requestRecoilState.projectPeriod !== "" &&
          requestRecoilState.projectBudget !== "" &&
          requestRecoilState.startingPoint !== "" &&
          requestRecoilState.contactPoint !== ""
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
      case "4":
        if (
          requestRecoilState.managerNameAndPosition !== "" &&
          requestRecoilState.clientName !== "" &&
          requestRecoilState.email !== "" &&
          requestRecoilState.contact !== "" &&
          requestRecoilState.siteName !== "" &&
          infoAgreeState
        ) {
          setNextState(true);
        } else {
          setNextState(false);
        }
        break;
    }
  }, [location, requestRecoilState, infoAgreeState, requestFileData]);

  const requestPostHandler = async () => {
    setIsLoading(true);
    const data = {
      clientName: requestRecoilState.clientName,
      contact: requestRecoilState.contact,
      contractPoint: requestRecoilState.contactPoint,
      email: requestRecoilState.email,
      files: requestFileData,
      managerNameAndPosition: requestRecoilState.managerNameAndPosition,
      projectBudget: requestRecoilState.projectBudget,
      projectDetail: requestRecoilState.projectDetail,
      projectName: requestRecoilState.projectName,
      projectPeriod: requestRecoilState.projectPeriod,
      projectPreparationStatus: requestRecoilState.projectPreparationStatus.map(
        (item) => item.replace("• ", "")
      ),
      projectRequestType: requestRecoilState.projectRequestType.map((item) =>
        item.replace("• ", "")
      ),
      siteName: requestRecoilState.siteName,
      startingPoint: requestRecoilState.startingPoint,
    };

    const formData = new FormData();

    for (const key in data) {
      // files라는 key에 대한 처리는 따로 해주어야 합니다.
      if (key !== "files") {
        formData.append(key, data[key]);
      }
    }

    if (data.files) {
      formData.append("files", data.files);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const response = await requestProject(data);

    if (response.isSuccess) {
      setIsLoading(false);
      resetRequestState();
      resetRequestFileData();
      resetInfoAgreeState();
      setIsContactModalOpen(true);
      navigate("/contact");
    } else {
      setIsLoading(false);
      alert("전송실패");
    }
  };

  return (
    <>
      <RequestHeaderStyle index={location.pathname.split("/")[3]}>
        <div className='request-nav'>
          {location.pathname.split("/")[3] === "1" ? (
            <Link to={"/contact"}>
              <img src={process.env.PUBLIC_URL + "/img/contactX.svg"} />
            </Link>
          ) : (
            <Link
              to={`/contact/request/${
                Number(location.pathname.split("/")[3]) - 1
              }`}
            >
              <img
                className='arrow'
                src={process.env.PUBLIC_URL + "/img/contactLeftArrow.svg"}
              />
            </Link>
          )}
          {location.pathname.split("/")[3] !== "4" ? (
            <Link
              to={`/contact/request/${
                Number(location.pathname.split("/")[3]) + 1
              }`}
              id='next-button'
              className={!nextState && "disabled"}
            >
              <img
                className='arrow'
                src={process.env.PUBLIC_URL + "/img/contactRightArrow.svg"}
              />
            </Link>
          ) : (
            <a
              id='next-button'
              className={!nextState && "disabled"}
              onClick={requestPostHandler}
            >
              <img
                src={process.env.PUBLIC_URL + "/img/contactRightArrow.svg"}
              />
            </a>
          )}
        </div>
        <div className='request-title'>
          <h1>{`0${Number(location.pathname.split("/")[3])}`}</h1>
          <p>{RequestTitleArray[Number(location.pathname.split("/")[3])]}</p>
        </div>
      </RequestHeaderStyle>
      <RequestHeaderLine progress={location.pathname.split("/")[3]}>
        <span className='progressline' />
      </RequestHeaderLine>
    </>
  );
};

export default RequestHeader;

const RequestHeaderStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  margin-bottom: 40px;

  color: #ffffff;

  @media (max-width: 1850px) {
    margin-bottom: 28px;
  }
  @media (max-width: 786px) {
    margin-bottom: 26px;
  }

  .request-nav {
    position: absolute;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;

    padding: 0px 34px;
    bottom: 80px;

    img {
      height: 74px;
      width: 74px;

      &.arrow {
        height: 74px;
        width: 100px;
      }

      &:hover {
        opacity: 0.5;
      }
    }

    @media (max-width: 1850px) {
      img {
        height: 52px;
        width: 52px;

        &.arrow {
          height: 52px;
          width: 70px;
        }
      }
    }
    @media (max-width: 786px) {
      display: none;
    }
  }

  .request-title {
    margin-left: 502px;

    h1 {
      text-align: left;
      font: normal normal 200 96px/56px Pretendard;
      letter-spacing: -1.92px;
      color: #ffffff;
      opacity: 1;

      margin-bottom: 28px;
    }

    p {
      text-align: left;
      font: normal normal 600 24px/24px Pretendard;
      letter-spacing: -0.48px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 1850px) {
      margin-left: 341px;

      h1 {
        text-align: left;
        font: normal normal 200 68px/56px Pretendard;
        letter-spacing: -1.36px;
        color: #ffffff;
        opacity: 1;
      }

      p {
        text-align: left;
        font: normal normal 600 16px/16px Pretendard;
        letter-spacing: -0.32px;
        color: #ffffff;
        opacity: 1;
      }
    }
    @media (max-width: 786px) {
      margin-left: 18px;

      h1 {
        text-align: left;
        font: normal normal 100 64px/56px Pretendard;
        letter-spacing: -1.28px;
        color: #ffffff;
        opacity: 1;

        margin-bottom: 28px;
      }

      p {
        text-align: left;
        font: normal normal 600 16px/16px Pretendard;
        letter-spacing: -0.32px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  #next-button {
    position: relative;

    &::after {
      display: ${(props) => {
        return props.index == 4 ? "inherit" : "none";
      }};
      content: "Project Request";
      position: absolute;
      bottom: -50px; /* 이미지 아래로 글자를 조정하세요 */
      left: -130px;
      width: 200px;
      text-align: right;
      font: normal normal 600 18px/56px Poppins;
      letter-spacing: -0.36px;
      color: #ffffff;
      opacity: 1;

      @media (max-width: 1850px) {
        bottom: -40px;
        left: -150px;

        text-align: right;
        font: normal normal 600 12px/56px Poppins;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
`;

const RequestHeaderLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 1px;
  background-color: #585858;

  margin-bottom: 124px;

  .progressline {
    display: block;
    width: ${(props) => `${props.progress * 25}%`};
    height: 2px;
    background-color: #ffffff;

    border-radius: 7px;

    transition: 0.5s width;
  }

  @media (max-width: 1850px) {
    margin-bottom: 66px;
  }

  @media (max-width: 786px) {
    margin-bottom: 64px;
  }
`;
