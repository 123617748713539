import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-web";
import plusButtonAnimation from "../assets/plusButton.json";
import "./PlusButton.css";
import { styled } from "styled-components";

const PlusButton = ({ onClick, visibleHeight }) => {
  const containerRef = useRef(null);
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: true,
      loop: false,
      animationData: plusButtonAnimation,
    });

    return () => {
      anim.destroy();
    };
  }, [isHovered]);

  return (
    <ButtonWrapper
      className='plusButtonWrapper'
      onClick={onClick}
      $footerHeight={visibleHeight}
    >
      <div className='plusButton' ref={containerRef}></div>
    </ButtonWrapper>
  );
};

export default PlusButton;

const ButtonWrapper = styled.div`
  position: fixed;
  top: ${(props) => `calc(120px - ${props.$footerHeight}px)`};
  transition: top 0.3s ease;

  @media (max-width: 1850px) {
    top: ${(props) => `calc(84px - ${props.$footerHeight}px)`};
  }

  @media (max-width: 786px) {
    top: calc(7%);
  }
`;
