import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Detail.css";
import XButton from "../components/XButton";
import PlusButton from "../components/PlusButton";
import Footer from "../components/Footer/Footer";
import MobileMenuButton from "../components/Header/MobileMenu";
import { workProjectId } from "../api/project-request";
import { keyframes, styled } from "styled-components";
import { useRecoilState } from "recoil";
import { footerHeightAtom } from "../store/footerHeight";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from "body-scroll-lock-upgrade";

const Files = ({ files, isDivVisible }) => {
  function determineMediaType(fileName) {
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "webp",
      "svg",
    ];
    const videoExtensions = [
      "mp4",
      "mkv",
      "avi",
      "flv",
      "mov",
      "wmv",
      "webm",
      "ogg",
    ];

    const extension = fileName.split(".").pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return "unknown";
    }
  }

  const ImgVideos = files.map((file) => {
    console.log(file, 'lslfk');
    if (determineMediaType(file.fileName) == "image") {
      return (
        <React.Fragment key={file.fileUrl + "desk"}>
          <img
            className={`detailImg ${isDivVisible ? "reduced" : ""}`}
            src={file.fileUrl}
            alt=''
          />
          <img
            className={`mobileDetail ${isDivVisible ? "reduced" : ""}`}
            src={file.fileUrl}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={file.fileUrl + "desk"}>
          <video
            className={`detailImg ${isDivVisible ? "reduced" : ""}`}
            alt=''
            autoPlay
            loop
            muted
          >
            <source src={file.fileUrl} />
          </video>
          <video
            className={`mobileDetail ${isDivVisible ? "reduced" : ""}`}
            src={file.fileUrl}
            alt=''
            autoPlay
            loop
            muted
          >
            <source src={file.fileUrl} />
          </video>
        </React.Fragment>
      );
    }
  });

  return <>{ImgVideos}</>;
};

const Detail = () => {
  let { id } = useParams();
  const [isPlusButtonVisible, setIsPlusButtonVisible] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [developers, setDevelopers] = useState([]);
  const [designers, setDesigners] = useState([]);
  const navigate = useNavigate();
  const { menu } = useParams();

  const [visibleHeight, setVisibleHeight] = useRecoilState(footerHeightAtom);

  useEffect(() => {
    const fetchData = async () => {
      const result = await workProjectId(id);
      if (result.isSuccess) {
        console.log(result.result.projectAttendantDtoList);
        setProjectData(result.result);
        const { developers, designers } = sortAndFilterByType(
          result.result.projectAttendantDtoList
        );
        setDevelopers(developers);
        setDesigners(designers);
      }
    };

    fetchData();
  }, [id]);

  function sortAndFilterByType(data) {
    // developerType이 존재하는 객체 리스트 필터링
    const developers = data
      .filter((item) => item.developerType !== null)
      .sort((a, b) => a.seq - b.seq);

    // designerType이 존재하는 객체 리스트 필터링
    const designers = data
      .filter((item) => item.designerType !== null)
      .sort((a, b) => a.seq - b.seq);

    return {
      developers,
      designers,
    };
  }

  const handleButtonClick = () => {
    setIsPlusButtonVisible((prevState) => !prevState);
    setIsDivVisible(!isDivVisible);
  };

  const modal = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 786) {
      if (isDivVisible && modal.current) {
        disableBodyScroll(modal);
      } else if (modal.current) {
        // 모달이 닫혔을 때 스크롤 잠금을 해제합니다.
        clearAllBodyScrollLocks();
      }
    }

    // 컴포넌트가 언마운트될 때 잠금을 해제합니다. (선택적)
    return () => clearAllBodyScrollLocks();
  }, [isDivVisible]);

  const DesignerType = designers.map((dev) => {
    return <p key={"devtype" + dev.id}>{dev.designerType}</p>;
  });

  const DesignerName = designers.map((dev) => {
    return <p key={"devname" + dev.id}>{dev.name}</p>;
  });

  const DeveloperType = developers.map((dev) => {
    return <p key={"devtype" + dev.id}>{dev.developerType}</p>;
  });

  const DeveloperName = developers.map((dev) => {
    return <p key={"devname" + dev.id}>{dev.name}</p>;
  });

  return (
    <div ref={modal}>
      <div className='detailPlusButton'>
        {isPlusButtonVisible ? (
          <PlusButton
            onClick={handleButtonClick}
            visibleHeight={visibleHeight}
          />
        ) : (
          <XButton onClick={handleButtonClick} visibleHeight={visibleHeight} />
        )}
      </div>
      {isDivVisible && (
        <SideViewDiv $footerHeight={visibleHeight}>
          <div className='mobileBackdrop'></div>
          <div className='detailBarWrapper'>
            <div className='bar1Wrapper'>
              <div className='detailBar1' />
            </div>
            <div className='bar2Wrapper'>
              <div className='detailBar2' />
            </div>
          </div>
          <div className='textDiv'>
            <h2>
              <DetailTitleTxt>
                {projectData?.projectName} / {projectData?.projectType}
              </DetailTitleTxt>
            </h2>
            <p className='date'>{projectData?.endDate}</p>
            <DetailDetailDiv className='textDiv1Wrapper'>
              <div className='job'>
                {DesignerType}
              </div>
              <div className='dev'>
                {DesignerName}
              </div>
            </DetailDetailDiv>
            <DetailDetailDiv className='textDiv2Wrapper'>
              <div className='job'>
                {DeveloperType}
              </div>
              <div className='dev'>
                {DeveloperName}
              </div>
            </DetailDetailDiv>
          </div>
        </SideViewDiv>
      )}

      {projectData && (
        <>
          {/* <img
            className={`detailImg ${isDivVisible ? "reduced" : ""}`}
            src={projectData.files[0].fileUrl} // Replace static file path with the dynamic one
            alt='detail'
          />
          <img
            className={`mobileDetail ${isDivVisible ? "reduced" : ""}`}
            src={projectData.files[0].fileUrl}
          /> */}
          <Files files={projectData.files} isDivVisible={isDivVisible} />
        </>
      )}
    </div>
  );
};

export default Detail;

const slidein = keyframes`
    0% {
      left: 100%; /* 시작 위치 */
    }
    100% {
      left: 0; /* 최종 위치 */
    }
`;

const backdropslidein = keyframes`
    0% {
      left: 100%; /* 시작 위치 */
      opacity: 0.1;
    }
    100% {
      left: 0; /* 최종 위치 */
      opacity: 0.7;
    }
`;

const SideViewDiv = styled.div`
  position: fixed;

  top: ${(props) => `calc(334px - ${props.$footerHeight}px)`};
  left: 34px;
  width: 30%;
  z-index: 1;
  color: #fff;
  font: normal normal 600 18px/36px Pretendard;
  white-space: nowrap;
  overflow: hidden;
  transition: top 0.3s ease;

  .detailBarWrapper {
    position: absolute;
    position: fixed;
    top: ${(props) => `calc(121px - ${props.$footerHeight}px)`};
    width: 30%;
    height: 100vh;
    direction: calc(180);
    transition: top 0.3s ease;
  }

  @media (max-width: 1850px) {
    top: ${(props) => `calc(202px - ${props.$footerHeight}px)`};
    .detailBarWrapper {
      top: ${(props) => `calc(144px - ${props.$footerHeight}px)`};
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    position: fixed;

    top: calc(7% + 96px);
    left: 0;
    margin-left: 18px;

    .detailBarWrapper {
      top: calc(7% + 35px);
    }

    .mobileBackdrop {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #000000;
      animation: ${backdropslidein} 0.5s forwards;
    }
  }
`;

const DetailTitleTxt = styled.span`
  font: normal normal 600 24px/32px Poppins;
  letter-spacing: -0.24px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 600 16px/32px Poppins;
    letter-spacing: -0.16px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 600 20px/32px Poppins;
    letter-spacing: -0.2px;
    color: #ffffff;
    opacity: 1;
  }
`;

const DetailDateTxt = styled.span`
  text-align: left;
  font: normal normal 600 20px/32px Poppins;
  letter-spacing: -0.2px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 600 14px/32px Poppins;
    letter-spacing: -0.14px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 600 14px/32px Poppins;
    letter-spacing: -0.14px;
    color: #ffffff;
    opacity: 1;
  }
`;

const DetailDetailDiv = styled.div`
  p {
    text-align: left;
    font: normal normal 600 18px/36px Pretendard;
    letter-spacing: -0.72px;
    color: #ffffff;
    opacity: 1;
    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 600 14px/36px Pretendard;
      letter-spacing: -0.56px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 768px) {
      text-align: left;
      font: normal normal 600 14px/36px Pretendard;
      letter-spacing: -0.56px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;
