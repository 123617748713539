import React from "react";
import XButton from "../XButton";
import ContactModalX from "../ContactModalX";
import { styled } from "styled-components";
function ContactModal({ closeModal }) {
  return (
    <>
      <ContactModalWrapper>
        <p>의뢰가 완료되었습니다. </p>
        <p>워킹데이 3일 이내 회신드리겠습니다.</p>
        <div className='ModalXButton'>
          <img
            src={process.env.PUBLIC_URL + "/img/policyX.png"}
            onClick={closeModal}
          />
        </div>
      </ContactModalWrapper>
    </>
  );
}

export default ContactModal;

const ContactModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 604px;
  height: 400px;

  border: 1px solid #585858;
  background-color: #000000;

  p {
    text-align: center;
    font: normal normal 500 20px/38px Pretendard;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 1;
  }

  .ModalXButton {
    position: absolute;
    top: 22px;
    right: 22px;
    img {
      width: 36px;
      height: 36px;
    }
  }

  @media (max-width: 1850px) {
    width: 394px;
    height: 260px;
    p {
      text-align: center;
      font: normal normal 500 14px/38px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
    .ModalXButton {
      position: absolute;
      top: 14px;
      right: 14px;
      img {
        width: 23px;
        height: 23px;
      }
    }
  }

  @media (max-width: 786px) {
    width: 300px;
    height: 300px;
    p {
      text-align: center;
      font: normal normal 500 16px/28px Pretendard;
      letter-spacing: -0.4px;
      color: #ffffff;
      opacity: 1;
    }
    .ModalXButton {
      position: absolute;
      top: 22px;
      right: 22px;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
`;
