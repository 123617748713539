import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { requestBudget, requestPeriod } from "../../api/project-request";
import { useRecoilState } from "recoil";
import { requestState } from "../../store/requestAtom";

const RequestComponent3 = () => {
  const [requestRecoilState, setRequestState] = useRecoilState(requestState);
  const [periods, setPeriods] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [periodIdx, setPeriodIdx] = useState("");
  const [budgetIdx, setBudgetIdx] = useState("");
  const [period1920, setPeriod1920] = useState("16.6%");
  const [budget1920, setBudget1920] = useState("16.6%");

  useEffect(() => {
    const fetchPeriodsAndBudgets = async () => {
      try {
        const periods = await requestPeriod();
        const budgets = await requestBudget();

        setPeriods(periods.result);
        setBudgets(budgets.result);
        
          setRequestState((prev) => {
            return {
              ...prev,
              projectPeriod: periods.result[0],
              projectBudget: budgets.result[0],
            };
          });
        
      } catch (error) {
        console.error("Failed to fetch periods and budgets", error);
      }
    };

    fetchPeriodsAndBudgets();
  }, []);

  const periodChangeHandler = (event) => {
    const targetLabel = event.target.parentElement; // 클릭한 radio button의 부모 label 요소
    const distance = targetLabel.offsetLeft + targetLabel.offsetWidth;
    setPeriod1920(`${distance}px`);

    setRequestState((prev) => {
      return { ...prev, projectPeriod: event.target.value };
    });
  };

  const budgetChangeHandler = (event) => {
    const targetLabel = event.target.parentElement; // 클릭한 radio button의 부모 label 요소
    const distance = targetLabel.offsetLeft + targetLabel.offsetWidth;
    setBudget1920(`${distance}px`);

    setRequestState((prev) => {
      return { ...prev, projectBudget: event.target.value };
    });
  };

  const Period = periods.map((p, idx) => {
    return (
      <label
        key={"period-option" + idx}
        className={`${
          requestRecoilState.projectPeriod === p ? "selected" : ""
        }`}
      >
        <input
          type='radio'
          value={p}
          name='period'
          onChange={(e) => {
            periodChangeHandler(e);
            setPeriodIdx(idx);
          }}
        />
        {p}
      </label>
    );
  });

  const Budget = budgets.map((b, idx) => {
    return (
      <label
        key={"budget-option" + idx}
        className={`${
          requestRecoilState.projectBudget === b ? "selected" : ""
        }`}
      >
        <input
          type='radio'
          value={b}
          name='budget'
          onChange={(e) => {
            budgetChangeHandler(e);
            setBudgetIdx(idx);
          }}
        />
        {b}
      </label>
    );
  });

  const contractChangeHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, contactPoint: event.target.value };
    });
  };

  const startingChangeHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, startingPoint: event.target.value };
    });
  };

  return (
    <SelectWrapper>
      <div className='first-column'>
        <SelectOptionWrapper $idx={periodIdx} $length1920={period1920}>
          <span className='input-title'>프로젝트 기간</span>
          <div className='options'>{Period}</div>
          <div className='progress'>
            <span className='progress-bar'></span>
          </div>
        </SelectOptionWrapper>
        <SelectOptionWrapper $idx={budgetIdx} $length1920={budget1920}>
          <span className='input-title'>프로젝트 예산</span>
          <div className='options'>{Budget}</div>
          <div className='progress'>
            <span className='progress-bar'></span>
          </div>
        </SelectOptionWrapper>
      </div>
      <div>
        <InputTextWrapper>
          <label htmlFor='project-name' className='input-title'>
            계약시점
          </label>
          <input
            name='project-name'
            id='project-name'
            placeholder='희망하시는 계약시점을 입력해주세요.'
            value={requestRecoilState.contactPoint}
            onChange={contractChangeHandler}
            autoComplete='off'
          />
        </InputTextWrapper>
        <InputTextWrapper>
          <label htmlFor='project-name' className='input-title'>
            착수시점
          </label>
          <input
            name='project-name'
            id='project-name'
            placeholder='희망하시는 착수시점을 입력해주세요.'
            value={requestRecoilState.startingPoint}
            onChange={startingChangeHandler}
            autoComplete='off'
          />
        </InputTextWrapper>
      </div>
    </SelectWrapper>
  );
};

export default RequestComponent3;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 604px;
    @media (max-width: 1850px) {
      width: 393px;
    }

    @media (max-width: 786px) {
      width: 100%;
      padding: 0px 18px;
    }
  }

  .first-column {
    margin-right: 98px;
    @media (max-width: 1850px) {
      margin-right: 66px;
    }

    @media (max-width: 786px) {
      margin-right: 0px;
    }
  }

  .input-title {
    text-align: left;
    font: normal normal 500 20px/20px Pretendard;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 28px;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 500 14px/14px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
      margin-bottom: 12px;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 16px/16px Pretendard;
      letter-spacing: -0.32px;
      color: #ffffff;
      opacity: 1;
      margin-bottom: 12px;
    }
  }
`;

const SelectOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-wrap: wrap;

  position: relative;
  height: 111px;

  margin-bottom: 52px;

  @media (max-width: 1850px) {
    height: 114px;
    margin-bottom: 28px;
  }

  @media (max-width: 786px) {
    height: 136px;
    margin-bottom: 36px;
  }

  .progress {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #585858;

    .progress-bar {
      display: block;
      width: ${(props) => `${props.$length1920}`};
      height: 2px;
      background-color: #ffffff;

      border-radius: 7px;

      transition: 0.5s width;
      @media (max-width: 1850px) {
        width: ${(props) => `${(props.$idx + 1) * 20}%`};
      }
    }
  }

  input {
    display: none;
  }

  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;

    border: 1px solid #707070;

    text-align: center;
    font: normal normal 500 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #707070;
    opacity: 1;

    &.selected {
      border: 1px solid #ffffff;

      text-align: center;
      font: normal normal 500 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }

  @media (max-width: 1850px) {
    .label {
      height: 30px;

      text-align: center;
      font: normal normal 500 12px/56px Pretendard;
      letter-spacing: -0.24px;
      color: #707070;
      opacity: 1;

      margin-left: 15px;

      &.selected {
        border: 1px solid #ffffff;

        text-align: center;
        font: normal normal 500 12px/56px Pretendard;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  @media (max-width: 786px) {
    .label {
      height: 30px;

      text-align: center;
      font: normal normal 500 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #707070;
      opacity: 1;

      &.selected {
        border: 1px solid #ffffff;

        text-align: center;
        font: normal normal 500 14px/56px Pretendard;
        letter-spacing: -0.28px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .options {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-column-gap: 26px;
    grid-row-gap: 0px;

    width: 100%;

    margin-bottom: 29px;

    @media (max-width: 1850px) {
      grid-template-columns: repeat(3, 87px);
      grid-column-gap: 15px;
      grid-row-gap: 8px;

      margin-bottom: 16px;
    }

    @media (max-width: 786px) {
      grid-template-columns: repeat(3, 100px);
      grid-column-gap: 12px;
      grid-row-gap: 16px;

      margin-bottom: 16px;
    }
  }
`;

const InputTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  &:last-child {
    margin-bottom: 0px;
  }

  height: 111px;

  @media (max-width: 1850px) {
    margin-bottom: 35px;
  }

  @media (max-width: 786px) {
    height: auto;
    margin-bottom: 35px;
  }

  input {
    height: 48px;

    padding: 0px 12px;

    text-align: left;
    font: normal normal normal 16px/1.2 Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    border: 1px solid #707070;
    background-color: transparent;

    &:focus-visible {
      outline: 1px solid #ffffff;
    }

    @media (max-width: 1850px) {
      height: 36px;

      text-align: left;
      font: normal normal normal 14px/1.2 Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      height: 48px;

      text-align: left;
      font: normal normal normal 14px/1.2 Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;
