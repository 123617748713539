import { styled } from "styled-components";
import PolicyModal from "../Modeal/PolicyModal";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { infoAgree, requestState } from "../../store/requestAtom";

import { ContactAtom, InfoAtom } from "../../store/modalAtom";

const RequestComponent4 = () => {
  const [requestRecoilState, setRequestState] = useRecoilState(requestState);
  const [infoAgreeState, setInfoAgree] = useRecoilState(infoAgree);

  const setInfoModal = useSetRecoilState(InfoAtom);
  const setContactModal = useSetRecoilState(ContactAtom);

  const agreeHandler = (event) => {
    setInfoAgree(event.target.checked);
  };

  const clientHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, clientName: event.target.value };
    });
  };

  const contactHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, contact: event.target.value };
    });
  };

  const sitenameHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, siteName: event.target.value };
    });
  };

  const emailHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, email: event.target.value };
    });
  };

  const managerNameAndPositionHandler = (event) => {
    setRequestState((prev) => {
      return { ...prev, managerNameAndPosition: event.target.value };
    });
  };

  return (
    <ClientWrapper>
      <div className='first-column'>
        <InputWrapper>
          <label htmlFor='project-client' className='input-title'>
            담당자명/직책
          </label>
          <input
            name='project-client'
            id='project-client'
            placeholder='담당자명과 직책을 입력해주세요.'
            onChange={managerNameAndPositionHandler}
            value={requestRecoilState.managerNameAndPosition}
            autoComplete='off'
          />
        </InputWrapper>
        <InputWrapper className='desktopLast'>
          <label htmlFor='project-company' className='input-title'>
            기관 또는 기업명
          </label>
          <input
            name='project-company'
            id='project-company'
            placeholder='기관 또는 기업명을 입력해주세요.'
            onChange={clientHandler}
            value={requestRecoilState.clientName}
            autoComplete='off'
          />
        </InputWrapper>
        <div className='agree-check'>
          <InputCheckWrapper>
            <input
              type='checkbox'
              className='check-list-input'
              name={"check-agree"}
              id={"check-agree"}
              onChange={agreeHandler}
            />
            <label className='check-list-label' htmlFor={"check-agree"}>
              <span onClick={() => setInfoModal(true)}>개인정보처리방침</span>에
              동의합니다.
            </label>
          </InputCheckWrapper>
        </div>
      </div>
      <div className='second-column'>
        <InputWrapper>
          <label htmlFor='project-email' className='input-title'>
            이메일
          </label>
          <input
            name='project-email'
            id='project-email'
            placeholder='회신 받으실 이메일 정보를 입력해주세요.'
            onChange={emailHandler}
            value={requestRecoilState.email}
            autoComplete='off'
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor='project-phone' className='input-title'>
            연락처
          </label>
          <input
            name='project-phone'
            id='project-phone'
            placeholder='연락처를 입력해주세요.'
            onChange={contactHandler}
            value={requestRecoilState.contact}
            autoComplete='off'
          />
        </InputWrapper>
        <InputWrapper className='mobileLast'>
          <label htmlFor='project-site' className='input-title'>
            사이트주소(선택)
          </label>
          <input
            name='project-site'
            id='project-site'
            placeholder='사이트 주소를 입력해주세요.'
            onChange={sitenameHandler}
            value={requestRecoilState.siteName}
            autoComplete='off'
          />
        </InputWrapper>
        <div className='agree-check-mobile'>
          <InputCheckWrapper>
            <input
              type='checkbox'
              className='check-list-input'
              name={"check-agree"}
              id={"check-agree"}
              onChange={agreeHandler}
            />
            <label className='check-list-label' htmlFor={"check-agree"}>
              <span onClick={() => setInfoModal(true)}>개인정보처리방침</span>에
              동의합니다.
            </label>
          </InputCheckWrapper>
        </div>
      </div>
    </ClientWrapper>
  );
};

export default RequestComponent4;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;

  &.desktopLast {
    margin-bottom: 0px;
  }

  &.mobileLast {
    margin-bottom: 38px;
  }

  @media (max-width: 1850px) {
    margin-bottom: 32px;

    &.mobileLast {
      margin-bottom: 32px;
    }
  }

  @media (max-width: 786px) {
    margin-bottom: 36px;
    &.desktopLast {
      margin-bottom: 36px;
    }

    &.mobileLast {
      margin-bottom: 0px;
    }
  }

  input {
    height: 48px;

    padding: 0px 12px;

    text-align: left;
    font: normal normal normal 16px/1.2 Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    border: 1px solid #707070;
    background-color: transparent;

    &:focus-visible {
      outline: 1px solid #ffffff;
    }

    @media (max-width: 1850px) {
      height: 36px;

      text-align: left;
      font: normal normal normal 14px/1.2 Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      height: 48px;

      text-align: left;
      font: normal normal normal 14px/1.2 Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;

const InputCheckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 30px;

  .check-list-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      display: flex;
      align-items: center;

      width: 150px;
      @media (max-width: 1850px) {
        width: 95px;
      }

      @media (max-width: 786px) {
        width: 45%;
      }
    }
  }

  .check-list-input {
    width: 24px;
    height: 24px;

    margin-right: 16px;

    background: #ffffff00;
    border: 1px solid #707070;
    cursor: none;
    appearance: none;

    &:checked {
      border: 1px solid #fff;
      background-color: #fff;
      background-image: url(${process.env.PUBLIC_URL + "/img/check.svg"});
      background-size: cover;
    }

    @media (max-width: 1850px) {
      width: 18px;
      height: 18px;

      margin-right: 8px;
    }

    @media (max-width: 786px) {
      width: 20px;
      height: 20px;

      margin-right: 12px;
    }
  }

  .check-list-label {
    text-align: left;
    font: normal normal 500 16px/20px Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    span {
      text-decoration: underline;
    }

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 500 12px/20px Pretendard;
      letter-spacing: -0.24px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 14px/20px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;

const ClientWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 604px;

    @media (max-width: 1850px) {
      width: 393px;
    }

    @media (max-width: 786px) {
      width: 100%;
      padding: 0px 18px;
      z-index: 0;
    }
  }

  .first-column {
    margin-right: 98px;
    @media (max-width: 1850px) {
      margin-right: 66px;
    }

    @media (max-width: 786px) {
      margin-right: 0px;
    }
  }

  .second-column {
    margin-bottom: 100px;

    @media (max-width: 786px) {
      margin-bottom: 0px;
    }
  }

  .input-title {
    text-align: left;
    font: normal normal 500 20px/56px Pretendard;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 1;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 500 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 16px/16px Pretendard;
      letter-spacing: -0.32px;
      color: #ffffff;
      opacity: 1;

      margin-bottom: 12px;
    }
  }

  .agree-check {
    @media (max-width: 786px) {
      display: none;
    }

    display: flex;
    flex-direction: row;

    width: auto;

    margin-top: 24px;

    @media (max-width: 1850px) {
      margin-top: 20px;
    }
  }

  .agree-check-mobile {
    @media (max-width: 786px) {
      display: flex;
    }

    display: none;
    flex-direction: row;

    width: auto;

    margin-top: 20px;
  }
`;
