import { atom } from "recoil";

const requestState = atom({
  key: "requestState",
  default: {
    clientName: "",
    contact: "",
    contactPoint: "",
    email: "",
    managerNameAndPosition: "",
    projectBudget: "",
    projectDetail: "",
    projectName: "",
    projectPeriod: "",
    projectPreparationStatus: [],
    projectRequestType: [],
    siteName: "",
    startingPoint: "",
  },
});

const requestFile = atom({
  key: "requestFile",
  default: null,
});

const infoAgree = atom({
  key: "infpo-agree",
  default: false,
});

export { requestState, requestFile, infoAgree };
