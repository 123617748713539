import React, { useEffect, useRef, useState } from "react";
import "./Main.css";
import "./Curation.css";
import ScrollAnimeWhite from "../components/ScrollAnimeWhite";
import ScrollAnimeBlack from "../components/ScrollAnimeBlack";
import ScrollBar from "../components/ScrollBar";
import { Link, useNavigate } from "react-router-dom";
import { thumbnail } from "../api/project-request"; 
import Layout from "../components/Blur";
import { useSetRecoilState } from "recoil";
import { navStyleState } from "../store/navStyleAtom";
import { keyframes, styled } from 'styled-components';

const Curation = () => {
  const navigate = useNavigate();
  const imageRefs = useRef([]);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [$currentImageIndex, set$currentImageIndex] = useState(0);
  const [isScrollBarHovered, setIsScrollBarHovered] = useState(false);
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const [thumbnails, setThumbnails] = useState([]); 
  const onChangeImageIndex = (newImageIndex) => {
    set$currentImageIndex(newImageIndex);
  };
  const setNavStyle = useSetRecoilState(navStyleState);

  const [isLoading, setLoading] = useState(false)

  // API 통신
  useEffect(() => {
    const fetchThumbnails = async () => {
      const result = await thumbnail();
      if (result.isSuccess) {
        setThumbnails(result.result);
      }
    };

    fetchThumbnails();
  }, []);

  // 스크롤 이벤트 핸들러 등록, 인덱스 조정
  useEffect(() => {
    const handleScroll = (e) => {
      if (isScrollBarHovered || isScrollLocked) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      const newScrollDirection = e.deltaY > 0 ? "down" : "up";

      if (newScrollDirection !== scrollDirection) {
        setScrollDirection(newScrollDirection);
      }

      if (!isScrollLocked) {
        setIsScrollLocked(true);

        setTimeout(() => {
          setIsScrollLocked(false);
        }, 850);

        const newImageIndex =
          newScrollDirection === "down"
            ? Math.min($currentImageIndex + 1, imageRefs.current.length - 1)
            : Math.max($currentImageIndex - 1, 0);

        set$currentImageIndex(newImageIndex);
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [$currentImageIndex, isScrollBarHovered, scrollDirection, isScrollLocked]);

  // 현재 이미지 인덱스가 변경 될 때 해당 이미지로 부드럽게 스크롤
  useEffect(() => {
    if (imageRefs.current[$currentImageIndex]) {
      imageRefs.current[$currentImageIndex].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [$currentImageIndex]);

  // useEffect(() => {
  //   if ($currentImageIndex === 1) {
  //     setNavStyle("black");
  //   } else {
  //     setNavStyle("white");
  //   }
  // }, [$currentImageIndex]);

  useEffect(() => {
    return () => {
      setNavStyle("white");
    };
  }, []);

  const handleImageRef = (index, ref) => {
    imageRefs.current[index] = ref;
  };

  if (isLoading) {
    return <BlackDiv></BlackDiv>
  }

  return (
    <Layout>
      <SlideInDiv />
      <div className='curationImgWrapper'>
        {thumbnails.map((thumbnail, index) => {
          return (
            <Link to={`/work/detail/${thumbnail.id}`} key={thumbnail.id}>
              <div
                className='hubbingWrapper section'
                ref={(ref) => handleImageRef(index, ref)}
                key={index}
              >
                <img
                  alt={thumbnail.fileName}
                  className='curationSlide-in hubbingImg'
                  src={thumbnail.fileUrl}
                  onMouseEnter={() => setIsScrollBarHovered(false)}
                />
                {index === 1 && <ScrollAnimeBlack />}
                {index !== 1 && <ScrollAnimeWhite />}
              </div>
            </Link>
          );
        })}
      </div>
      <ScrollBar
        onHover={setIsScrollBarHovered}
        $currentImageIndex={$currentImageIndex}
        totalImages={thumbnails.length}
        onChangeImageIndex={onChangeImageIndex} // pass function as prop
      />
      <div className='curationImgWrapper-m'>
        {thumbnails.map((thumbnail, index) => {
          return (
            <MoblieImg
              thumbnail={thumbnail}
              action={() => {
                onChangeImageIndex(index);
              }}
              key={"mobile-curation-img" + index}
            />
          );
        })}
      </div>
    </Layout>
  );
};

const MoblieImg = ({ thumbnail, action }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          action();
        }
      },
      { root: null, rootMargin: "0px", threshold: 1.0 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <>
      <Link to={`/work/detail/${thumbnail.id}`} key={thumbnail.id}>
        <img
          alt={thumbnail.fileName}
          className='hubbingImg-m'
          src={thumbnail.fileUrl}
          ref={imageRef}
        />
      </Link>
    </>
  );
};

export default Curation;

const slideOut = keyframes`
  0% {
    width: 100vw;
  }
  100% {
    width: 0vw;
  }
`

const SlideInDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 100vw;

  animation: ${slideOut} 0.4s forwards;

  z-index: 3;
`

const BlackDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 100vw;

  z-index: 3;
`