import React from "react";
import lottie from "lottie-web";
import xButtonAnimation from "../assets/xButton.json";
import "./XButton.css";
import { styled } from "styled-components";

const XButton = ({ onClick, visibleHeight }) => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      animationData: xButtonAnimation,
      loop: false,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <ButtonWrapper
      className='xButtonWrapper'
      onClick={onClick}
      $footerHeight={visibleHeight}
    >
      <div className='xButton' ref={containerRef}></div>
    </ButtonWrapper>
  );
};

export default XButton;

const ButtonWrapper = styled.div`
  position: fixed;
  top: ${(props) => `calc(120px - ${props.$footerHeight}px)`};
  transition: top 0.3s ease;

  @media (max-width: 1850px) {
    top: ${(props) => `calc(84px - ${props.$footerHeight}px)`};
  }

  @media (max-width: 786px) {
    top: ${(props) => `calc(7%)`};
  }
`;
