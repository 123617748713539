import { styled } from "styled-components";

export default function GridBackground() {
  return (
    <GridContaioner>
      <div className='grid-col-1' />
      <div className='grid-col-2' />
      <div className='grid-col-3' />
      <div className='grid-row-1' />
      <div className='grid-row-2' />
      <div className='grid-row-3' />
    </GridContaioner>
  );
}

const GridContaioner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  .grid-col-1 {
    position: absolute;
    left: 80px;
    top: 0;

    width: 1px;
    height: 100vh;
    background-color: #ffffff;

    opacity: 0.18;

    @media (max-width: 1850px) {
      left: 53px;
    }

    @media (max-width: 786px) {
      left: 18px;
    }
  }

  .grid-col-2 {
    position: absolute;
    left: 1124px;
    top: 0;

    width: 1px;
    height: 100vh;
    background-color: #ffffff;

    opacity: 0.18;

    @media (max-width: 1850px) {
      left: 749px;
    }

    @media (max-width: 786px) {
      left: 270px;
    }
  }

  .grid-col-3 {
    position: absolute;
    left: 1839px;
    top: 0;

    width: 1px;
    height: 100vh;
    background-color: #ffffff;

    opacity: 0.18;
    @media (max-width: 1850px) {
      left: 1226px;
    }

    @media (max-width: 786px) {
      display: none;
    }
  }

  .grid-row-1 {
    position: absolute;
    left: 0;
    top: 88px;

    width: 100vw;
    height: 1px;
    background-color: #ffffff;

    opacity: 0.18;
    @media (max-width: 1850px) {
      top: 60px;
    }

    @media (max-width: 786px) {
      top: 105px;
    }
  }

  .grid-row-2 {
    position: absolute;
    left: 0;
    top: 539px;

    width: 100vw;
    height: 1px;
    background-color: #ffffff;

    opacity: 0.18;
    @media (max-width: 1850px) {
      top: 367px;
    }

    @media (max-width: 786px) {
      top: 390px;
    }
  }

  .grid-row-3 {
    position: absolute;
    left: 0;
    top: 991px;

    width: 100vw;
    height: 1px;
    background-color: #ffffff;

    opacity: 0.18;
    @media (max-width: 1850px) {
      top: 675px;
    }

    @media (max-width: 786px) {
      top: 716px;
    }
  }
`;
