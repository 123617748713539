import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Work.css";
import Footer from "../components/Footer/Footer";
import MobileMenuButton from "../components/Header/MobileMenu";
import { workProject } from "../api/project-request";
import { styled } from "styled-components";

const Work = () => {
  const [workList, setWorkList] = useState({ full: [], two: [] });
  const [areaList, setAreaList] = useState([]);
  useEffect(() => {
    // Call your API function when the component mounts
    const fetchProjects = async () => {
      const { result, isSuccess } = await workProject();
      if (isSuccess) {
        const dataList = result;

        const setList = {
          full: [],
          two: [],
        };

        dataList.forEach((data) => {
          if (data.gridAreaType === "full") {
            setList.full.push(data);
          } else {
            setList.two.push(data);
          }
        });

        Object.keys(setList).forEach((key) => {
          setList[key].sort((a, b) => a.projectAreaId - b.projectAreaId);
        });

        setWorkList(setList);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const list = workList;

    const result = [];

    let fullIndex = 0;
    let twoIndex = 0;

    while (fullIndex < list.full.length || twoIndex < list.two.length) {
      if (fullIndex < list.full.length) {
        result.push(list.full[fullIndex]);
        fullIndex++;
      }

      for (let i = 0; i < 2 && twoIndex < list.two.length; i++) {
        result.push(list.two[twoIndex]);
        twoIndex++;
      }
    }
    setAreaList(result);
  }, [workList]);

  const Areas = areaList.map((area) => {
    console.log(area)
    return (
      <Link
        to={`/work/detail/${area.projectInfoDto.id}`}
        key={"Area" + area.projectInfoDto.id}
        className={area.gridAreaType + " gridDiv"}
      >
        <div className='blur-area' />
        <img src={area.projectInfoDto.mainFile.fileUrl} alt='' />
        <div className={`text-area ${area.gridAreaType == 'two' ? "two" : ""}`}>
          <div className='text-area-title'>
            <span>{area.projectInfoDto.projectName}</span>
          </div>
          <hr className='text-area-line' />
          <div className='text-area-context'>
            <span>Project End Date</span>
            <span>{area.projectInfoDto.endDate}</span>
          </div>
          <div className='text-area-context'>
            <span>Client</span>
            <span>{area.projectInfoDto.clientName}</span>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <WorkPageWrapper>
      <GridContainer>{Areas}</GridContainer>
    </WorkPageWrapper>
  );
};

export default Work;

const WorkPageWrapper = styled.div`
  width: 100%;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .gridDiv {
    position: relative;
    height: 600px;

    overflow: hidden;

    &.full {
      grid-column: span 2;
      height: 860px;
      .text-area {
        width: 682px;
      }
      @media (max-width: 1850px) {
        height: 572px;
        .text-area {
          width: 495px;
        }
      }
      @media (max-width: 768px) {
        height: 300px;
        .text-area {
          width: 495px;
        }
      }
    }

    .text-area {
      position: absolute;
      opacity: 0;
      width: 516px;

      right: 34px;
      bottom: 64px;
      &.two {
        bottom: 20px;
      }
      transition: 0.3s ease-out;
      .text-area-title {
        text-align: left;
        font: normal normal bold 32px/56px Poppins;
        letter-spacing: -0.64px;
        color: #ffffff;
        opacity: 1;
      }
      .text-area-line {
        color: #ffffff;
        margin: 0;
        width: 0;
        transition: 0.3s ease-out;
      }
      .text-area-context {
        display: flex;
        justify-content: space-between;

        text-align: left;
        font: normal normal normal 24px / 70px Poppins;
        letter-spacing: -0.48px;
        color: #ffffff;
        opacity: 1;

        border-bottom: #585858 1px solid;
      }

      @media (max-width: 1850px) {
        width: 327px;
        right: 36px;
        bottom: 44px;
        .text-area-title {
          text-align: left;
          font: normal normal bold 20px/56px Poppins;
          letter-spacing: -0.4px;
          color: #ffffff;
          opacity: 1;
        }
        .text-area-context {
          text-align: right;
          font: normal normal normal 14px/56px Poppins;
          letter-spacing: -0.28px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;

      z-index: -1;
      transition: 0.3s ease-out;
    }
    .blur-area {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #000000;
      opacity: 0;
      transition: 0.3s ease-out;
    }
    @media (hover) {
      &:hover {
        .blur-area {
          opacity: 0.7;
        }
        img {
          transform: scale(1.1);
        }
        .text-area {
          opacity: 1;
          hr {
            width: 100%;
          }
        }
      }
    }
    @media (max-width: 1850px) {
      height: 400px;
    }
    @media (max-width: 786px) {
      height: 200px;
      .blur-area {
        display: none;
      }
      .text-area {
        display: none;
      }
    }
  }
`;

const GridDiv = styled.div``;
