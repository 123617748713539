import { atom } from "recoil";

const InfoAtom = atom({
  key: "InfoModalAtom",
  default: false,
});

const ContactAtom = atom({
  key: "ContactModalAtom",
  default: false,
});

export { InfoAtom, ContactAtom };
