import React, { useState, useEffect } from "react";

function Layout({ children }) {
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     setMousePosition({ x: event.clientX, y: event.clientY });
  //   };

  //   // 컴포넌트가 마운트될 때, window 객체에 이벤트 리스너를 부착합니다.
  //   window.addEventListener("mousemove", handleMouseMove);

  //   // 컴포넌트가 언마운트될 때, 이벤트 리스너를 제거합니다.
  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []); // 의존성 배열이 빈 배열이므로, 이 useEffect는 컴포넌트가 마운트될 때 한 번만 실행됩니다.

  return (
    <div className='MainBg'>
      <div className='BlurWrapper'>
        {/* <div
          className="BlurArea"
          style={{
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
          }}
        /> */}
      </div>

      {children}
    </div>
  );
}

export default Layout;
