import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import spinnerAnimation from "../assets/spinner.json";
import "./Spinner.css";

const Spinner = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      animationData: spinnerAnimation,
      loop: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <>
      <div className="spinnerWrapper">
        <div className="spinner" ref={containerRef} />
      </div>
    </>
  );
};

export default Spinner;