import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./About.css";
import Lottie from "lottie-web";
import animationData from "../assets/scrollAnimeWhite.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { projectProcess, service, partner } from "../api/project-request";
import GridBackground from "../components/background/GridBackground";
import { styled } from "styled-components";
import ToTopButton from "../components/ToTop";

const About = () => {
  const navigate = useNavigate();
  const { menu } = useParams();
  const [data, setData] = useState([]);
  const [isScrollAtTop, setIsScrollAtTop] = useState(true);
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(true);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await projectProcess();
        setData(response.result.dataList);
        console.log(response.result.dataList);
      } catch (error) {
        setData("서버오류");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  // 대분류(projectProcessType) 순서에 따라 정렬
  const categoryOrder = [
    "PLAN",
    "DESIGN",
    "DEVELOPMENT",
    "INSPECTION",
    "OUTPUTS",
  ];
  const categoryFirstTitle = {
    PLAN: "기획 및 UX/UI",
    DESIGN: "GUI/",
    DEVELOPMENT: "Web/App 개발",
    INSPECTION: "검수/테스트",
    OUTPUTS: "산출물 고객 인도",
  };
  const orderedData = data.sort((a, b) => {
    return (
      categoryOrder.indexOf(a.projectProcessTypeId) -
      categoryOrder.indexOf(b.projectProcessTypeId)
    );
  });

  const groupedData = orderedData.reduce((acc, item) => {
    if (!acc[item.projectProcessType]) {
      acc[item.projectProcessType] = [];
    }
    acc[item.projectProcessType].push(item);
    return acc;
  }, {});

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrollAtTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerRef = useRef(null);

  const style = {
    width: "70px",
    height: "70px",
  };

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: true,
      loop: true,
      animationData: animationData,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [partners, setPartners] = useState([]);
  const [servicedate, setServiceDate] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await projectProcess();
        setData(response.result.dataList);
        const responseService = await service();
        const responsePartners = await partner();
        setServiceDate(responseService.result);
        setPartners(responsePartners.result);
      } catch (error) {
        setData("서버오류");
        setServiceDate("서버오류");
      }
    };

    fetchData();
  }, []);

  const categoryOrderService = [...new Set(servicedate.sort((a, b) => a.serviceTypeId - b.serviceTypeId).map(item => item.serviceType))];

  
  const orderedServiceData = servicedate.sort(
    (a, b) =>
    categoryOrderService.indexOf(a.serviceTypeId) -
    categoryOrderService.indexOf(b.serviceTypeId)
    );
    const groupedDtaas = orderedServiceData.reduce((acc, item) => {
      if (!acc[item.serviceType]) {
        acc[item.serviceType] = [];
      }
      acc[item.serviceType].push(item);
      return acc;
    }, {});
    console.log(orderedServiceData)

  return (
    <AboutStyle>
      <GridBackground />
      <div className='aboutTextWrapper'>
        <div className='aboutText'>
          <p className={`text-animation ${active ? "active" : ""}`}>
            <AboutTextTitle>
              경계boundary, 境界 : 혁신이 태동하는 곳
            </AboutTextTitle>
          </p>

          <div className='aboutText2'>
            <p>
              <AboutTextTitle2>
                boundary는 당신의 생각이 세상에 변화를 가져올 수 있도록
              </AboutTextTitle2>
            </p>
            <p>
              {" "}
              <AboutTextTitle2>
                현실과 이상이 만나는 경계에서 고민합니다.
              </AboutTextTitle2>
            </p>
            <p>
              <AboutTextTitle2>
                호기심과 모험심을 연료로, 이유있는 기준을 탐구하고 시험합니다.
              </AboutTextTitle2>
            </p>
            <div className={`underLine ${active ? "active" : ""}`} />
          </div>
        </div>
      </div>
      <div className='serviceContainer'>
        <div className='serviceWrapper'>
          <h1>
            <AboutTitle>Service</AboutTitle>
          </h1>
        </div>

        {Object.entries(groupedDtaas).map(([category, items], index) => {
          const character = String.fromCharCode(65 + index);
          return (
            <div
              key={category}
              className={`categoryContainer ${index === 0 ? "first" : ""}`}
            >
              <div className={`categoryWrapper ${index === 0 ? "first" : ""}`}>
                <h2 className='category'>
                  <AboutSubTitle>({character})</AboutSubTitle>
                </h2>
                <h2 className='category-title'>
                  <AboutSubTitle>{category}</AboutSubTitle>
                </h2>
              </div>
              <ul>
                {items.map((item, idx) => {
                  return (
                    <div className='categoryP' key={"categoryP" + idx}>
                      <p>
                        <AboutServiceContent>
                          {item.content}
                        </AboutServiceContent>
                      </p>
                    </div>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div className='processContainer'>
        <h1>
          <AboutTitle>Project Process</AboutTitle>
        </h1>
        <div className='projectPWrapper'>
          <p className='projectP'>
            <ProjectSubTitle>
              워터폴 접근을 기반으로 [기획-디자인-개발-검수] Process를
              단계적으로 진행합니다.
              <br></br>
              기능 단위 WBS 및 Live-Update 기반의 Project Management 시스템을
              제공합니다.
            </ProjectSubTitle>
          </p>
        </div>
        <div className='processCategoryContainer'>
          {Object.entries(groupedData).map(([category, items], index) => {
            const numericRepresentation = `${index + 1}.0`;
            const uniqueClassName2 = `indexClassName${index + 1}`;
            return (
              <div key={category} className='categoryContainer'>
                <div className='categoryWrapper2'>
                  <h2 className='categoryNumber'>
                    <ProjectProcessNum>
                      {numericRepresentation}
                    </ProjectProcessNum>
                  </h2>
                  <h2 className='project'>
                    <ProjectProcessTitle>{category}</ProjectProcessTitle>
                  </h2>
                  <ProjectProcessSubTitle>
                    {categoryFirstTitle[category]}
                  </ProjectProcessSubTitle>
                </div>
                <ul className={`projectList ${uniqueClassName2}`}>
                  {items.map((item, index) => {
                    const uniqueClassName = `customClassName${index + 1}`;

                    return (
                      <div
                        className={`categoryP ${uniqueClassName}`}
                        key={item.sequence}
                      >
                        <p>
                          <ProjectProcessContent>
                            {item.content ? item.content : "\u200B"}
                          </ProjectProcessContent>
                        </p>
                      </div>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className='subDescriptWrapper'>
        <p className='subDescript'>
          <SubDescriptionTxt>
            * 위 산출물들은 예시이며, 계약 사항에 따라 달라질 수 있습니다.
          </SubDescriptionTxt>
        </p>
      </div>
      <div className='partnerWrapper'>
        <h1>
          <AboutTitle>Partners</AboutTitle>
        </h1>
        <div className='partnerImgWrapperDiv'>
          {partners.map((partner) => (
            <div className='partnerImgWrapper' key={partner.id}>
              <img src={partner.fileUrl} alt={partner.fileName} />
            </div>
          ))}
        </div>
      </div>

      <div className={`aboutScroll ${isScrollAtTop ? "" : "hide"}`}>
        <div ref={containerRef} style={style} className='aboutScroll' />
      </div>
      {isScrollAtTop ? null : (
        <div className='arrowBox' onClick={scrollToTop}>
          <ToTopButton />
        </div>
      )}
    </AboutStyle>
  );
};

export default About;

const AboutStyle = styled.div`
  .serviceWrapper {
    margin-left: 32px;
    margin-right: 32px;

    @media (max-width: 1850px) {
      margin-left: 35px;
      margin-right: 35px;
    }
    @media (max-width: 768px) {
      margin-left: 18px;
      margin-right: 18px;
    }
  }
  .categoryWrapper {
    position: relative;
    width: calc(100% - 64px);
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin-left: 32px;
    margin-right: 32px;

    &.first {
      border-top: 1px solid #ffffff;
    }

    @media (max-width: 1850px) {
      width: calc(100% - 70px);
      margin-left: 35px;
      margin-right: 35px;
    }
    @media (max-width: 768px) {
      width: calc(100% - 36px);
      margin-left: 18px;
      margin-right: 18px;
    }

    .category-title {
      position: absolute;
      top: 0;
      left: 704px;

      @media (max-width: 1850px) {
        width: calc(100% - 70px);
        left: 459px;
      }
      @media (max-width: 768px) {
        position: relative;
        left: 0px;
      }
    }
  }

  .categoryContainer ul {
    padding: 0;
    margin-left: 736px;
    text-align: start;

    > div {
      width: 100%;
    }

    > div:last-child {
      p {
        border: none;
      }
    }

    @media (max-width: 1850px) {
      margin-left: 494px;
    }
    @media (max-width: 768px) {
      position: relative;
      margin-left: 0px;

      > div {
        width: calc(100%);
        span {
          display: inline-block;
        }
      }
    }
  }

  .categoryP {
    p {
      margin-right: 32px;

      border-bottom: 1px solid #585858;
      @media (max-width: 1850px) {
        margin-right: 35px;
      }
      @media (max-width: 768px) {
        margin-left: 18px;
        margin-right: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

  .serviceContainer {
    margin-top: 164px;

    @media (max-width: 1850px) {
      margin-top: 174px;
    }
    @media (max-width: 768px) {
      margin-top: 91px;
    }

    .categoryContainer:last-child {
      ul {
        > div:last-child {
          p {
            border-bottom: 1px solid #585858;
          }
        }
      }
    }
  }

  .processContainer {
    margin-top: 280px;
    @media (max-width: 1850px) {
      margin-top: 220px;
    }
    @media (max-width: 768px) {
      margin-top: 104px;
    }

    h1,
    .projectPWrapper {
      width: calc(100% - 68px);
      margin-left: 34px;

      @media (max-width: 1850px) {
        width: calc(100% - 70px);
        margin-left: 35px;
        margin-right: 35px;
      }
      @media (max-width: 768px) {
        width: calc(100% - 36px);
        margin-left: 18px;
        margin-right: 18px;
      }
      .projectP {
        margin-bottom: 40px;
        @media (max-width: 1850px) {
          margin-bottom: 28px;
        }
        @media (max-width: 768px) {
          margin-bottom: 16px;
          br {
            display: none;
          }
        }
      }
    }

    .categoryContainer {
      margin-right: 34px;
      @media (max-width: 1850px) {
        margin-right: 35px;
      }
      @media (max-width: 768px) {
        margin-right: 18px;
        margin-bottom: 42px;
      }

      .categoryWrapper2 {
        position: relative;

        width: calc(100% - 424px);
        margin-left: 424px;
        @media (max-width: 1850px) {
          width: calc(100% - 341px);
          margin-left: 341px;
        }
        @media (max-width: 768px) {
          width: calc(100% - 18px);
          margin-left: 18px;
        }

        > span {
          position: absolute;
          left: 702px;
          @media (max-width: 1850px) {
            left: 357px;
          }
          @media (max-width: 768px) {
            left: 0px;
            right: 0px;
            margin-bottom: 5px;
          }
        }
      }

      .projectList {
        margin-left: 1124px;
        width: calc(100% - 1124px);
        p {
          margin-right: 0px;
        }
        @media (max-width: 1850px) {
          margin-left: 698px;
          width: calc(100% - 698px);
        }
        @media (max-width: 768px) {
          margin-left: 0px;
          width: 100%;
        }
      }

      > ul {
        > div:last-child {
          p {
            border-bottom: 1px solid #585858;
          }
        }
      }
    }
  }
  .subDescriptWrapper {
    margin-left: 1124px;

    @media (max-width: 1850px) {
      margin-left: 698px;
    }
    @media (max-width: 768px) {
      margin-left: 18px;
      width: 100%;
    }
  }
  svg text {
    font-family: "Poppins", sans-serif;
  }
`;

const AboutTextTitle = styled.span`
  text-align: left;
  font: normal normal bold 24px/40px Pretendard;
  letter-spacing: -0.36px;
  color: #ffffff;
  opacity: 1;

  margin-bottom: 40px;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal bold 16px/40px Pretendard;
    letter-spacing: -0.24px;
    color: #ffffff;
    opacity: 1;

    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal bold 16px/40px Pretendard;
    letter-spacing: -0.24px;
    color: #ffffff;
    opacity: 1;

    margin-bottom: 24px;
  }
`;
const AboutTextTitle2 = styled.span`
  text-align: left;
  font: normal normal 500 18px/32px Pretendard;
  letter-spacing: -0.27px;
  color: #a2a2a2;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 500 14px/26px Pretendard;
    letter-spacing: -0.21px;
    color: #a2a2a2;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 500 14px/24px Pretendard;
    letter-spacing: -0.21px;
    color: #a2a2a2;
    opacity: 1;
  }
`;

const AboutTitle = styled.span`
  text-align: left;
  font: normal normal 300 84px/125px Poppins;
  letter-spacing: -1.68px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 300 44px/65px Poppins;
    letter-spacing: -0.88px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 300 32px/48px Poppins;
    letter-spacing: -0.64px;
    color: #ffffff;
    opacity: 1;
  }
`;

const AboutSubTitle = styled.span`
  text-align: left;
  font: normal normal bold 24px/56px Poppins;
  letter-spacing: -0.48px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal bold 14px/56px Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal bold 14px/56px Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }
`;

const AboutServiceContent = styled.span`
  text-align: left;
  font: normal normal normal 24px/56px Poppins;
  letter-spacing: -0.48px;
  color: #ffffff;
  opacity: 1;

  margin-left: 390px;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal normal 14px/56px Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;

    margin-left: 204px;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal normal 14px/1.2 Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;

    margin-left: 83px;
  }
`;

const ProjectSubTitle = styled.span`
  text-align: left;
  font: normal normal normal 24px/42px Pretendard;
  letter-spacing: -0.48px;
  color: #868686;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal normal 16px/28px Pretendard;
    letter-spacing: -0.32px;
    color: #868686;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal normal 12px/24px Pretendard;
    letter-spacing: -0.24px;
    color: #868686;
    opacity: 1;
  }
`;

const ProjectProcessNum = styled.span`
  text-align: left;
  font: normal normal 200 156px/56px Poppins;
  letter-spacing: -6.24px;
  color: #262626;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 200 100px/56px Poppins;
    letter-spacing: -4px;
    color: #262626;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 200 84px/56px Poppins;
    letter-spacing: -3.36px;
    color: #262626;
    opacity: 1;
  }
`;

const ProjectProcessTitle = styled.span`
  text-align: left;
  font: normal normal bold 32px/56px Poppins;
  letter-spacing: -0.64px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal bold 20px/56px Poppins;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: inline-block;
    text-align: left;
    font: normal normal bold 14px/1.2 Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
    padding-bottom: 12px;
  }
`;

const ProjectProcessSubTitle = styled.span`
  text-align: left;
  font: normal normal bold 24px/56px Pretendard;
  letter-spacing: -0.48px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal bold 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: inline-block;
    text-align: right;
    font: normal normal bold 14px/1.2 Pretendard;
    letter-spacing: -0.28px;
    color: #707070;
    opacity: 1;
    padding-bottom: 12px;
  }
`;

const ProjectProcessContent = styled.span`
  text-align: left;
  font: normal normal normal 24px/56px Pretendard;
  letter-spacing: -0.48px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal normal 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal normal 14px/1.2 Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }
`;

const SubDescriptionTxt = styled.span`
  text-align: left;
  font: normal normal normal 16px/56px Pretendard;
  letter-spacing: -0.32px;
  color: #868686;
  opacity: 0.5;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal normal 12px/56px Pretendard;
    letter-spacing: -0.24px;
    color: #868686;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal normal 12px/56px Pretendard;
    letter-spacing: -0.24px;
    color: #868686;
    opacity: 1;
  }
`;
