import { useEffect, useState } from "react";
import { requestStatus, requestType } from "../../api/project-request";
import { styled } from "styled-components";
import Check from "../../assets/check.svg";
import { useRecoilState } from "recoil";
import { requestState } from "../../store/requestAtom";

const RequestComponent1 = () => {
  const [requestRecoilState, setRequestState] = useRecoilState(requestState);

  const buttonClickHandler = (type) => {
    if (requestRecoilState.projectRequestType.includes(type)) {
      setRequestState((prev) => {
        return {
          ...prev,
          projectRequestType: prev.projectRequestType.filter(
            (item) => item !== type
          ),
        };
      });
    } else {
      setRequestState((prev) => {
        return {
          ...prev,
          projectRequestType: [...prev.projectRequestType, type],
        };
      });
    }
  };

  // 버튼 생성
  const [requestTypes, setRequestTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestType();
      if (response.isSuccess) {
        setRequestTypes(response.result);
      }
    };

    fetchData();
  }, []);

  const Buttons = requestTypes.map((type, idx) => {
    return (
      <SelectButton
        onClick={() => buttonClickHandler(type)}
        className={
          requestRecoilState.projectRequestType.includes(type) ? "selected" : ""
        }
      >
        <img src={Check} />
        {type}
      </SelectButton>
    );
  });

  return <ButtonWrapper>{Buttons}</ButtonWrapper>;
};

export default RequestComponent1;

const ButtonWrapper = styled.div`
  display: grid;

  width: 100%;

  grid-template-columns: repeat(5, 200px);
  grid-column-gap: 96px;
  grid-auto-rows: 48px;
  grid-row-gap: 44px;

  @media (max-width: 1850px) {
    grid-template-columns: repeat(5, 146px);
    grid-column-gap: 43px;
    grid-auto-rows: 36px;
    grid-row-gap: 32px;
  }

  @media (max-width: 786px) {
    grid-template-columns: repeat(2, 156px);
    grid-column-gap: 12px;
    grid-auto-rows: 48px;
    grid-row-gap: 12px;
  }
`;

const SelectButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-align: center;
  font: normal normal 500 18px/56px Pretendard;
  letter-spacing: -0.36px;
  color: #ffffff;
  opacity: 1;

  background-color: transparent;
  border: 1px solid #707070;

  transition: 0.2s all;

  @media (hover) {
    &:hover {
      background-color: #262626;
    }
  }

  img {
    display: none;
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }

  &.selected {
    width: 200px;
    height: 48px;
    text-align: center;
    font: normal normal 600 18px/56px Pretendard;
    letter-spacing: -0.36px;
    color: #000000;
    opacity: 1;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    img {
      display: block;
    }
  }

  @media (max-width: 1850px) {
    text-align: center;
    font: normal normal 500 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;

    img {
      width: 20px;
      height: 20px;
    }

    &.selected {
      width: 146px;
      height: 36px;
      text-align: center;
      font: normal normal 600 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #000000;
      opacity: 1;
    }
  }

  @media (max-width: 786px) {
    width: 156px;
    height: 48px;
    margin: 0;

    text-align: center;
    font: normal normal 500 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
    &.selected {
      width: 156px;
      height: 48px;
      font: normal normal 600 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #000000;
      opacity: 1;
    }
  }
`;
