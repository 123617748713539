import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import animationData from "../assets/scrollAnimeBlack.json";

const ScrollAnimeBlack = () => {
  const containerRef = useRef(null);

  const style = {
    width: "70px",
    height: "70px",
  };

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: true,
      loop: true,
      animationData: animationData,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <>
      <div ref={containerRef} style={style} className="blackScroll" />
    </>
  );
};

export default ScrollAnimeBlack;
