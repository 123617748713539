import React from "react";
import { useState, useEffect } from "react";
import "./Main.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  curationState,
  fillBoxState,
  showContentState,
} from "../store/curationAtom";
import { styled } from "styled-components";
import GridBackground from "../components/background/GridBackground";
const Main = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showContent, setShowContent] = useRecoilState(showContentState); //추가
  const [curationVisible, setCurationVisible] = useRecoilState(curationState);
  const [fillBox, setFillBox] = useRecoilState(fillBoxState);
  const navigate = useNavigate();
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleCurationButtonClick = () => {
    //추가
    setShowContent(false);
    setCurationVisible(true);
    setTimeout(() => {
      setFillBox(true);
    }, 1000);

    navigate("/curation");
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      setMousePosition({ x: 0, y: 0 });
    };

    window.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    setShowContent(true);
    setCurationVisible(false);
    setFillBox(false);
  }, []);

  return (
    <>
      <GridBackground />
      <div className={`content ${!curationVisible ? "" : "content-after"}`}>
        <p className={`text ${!curationVisible ? "" : "fade-out"}`}>
          <MainTitleTxt>We explore and test</MainTitleTxt>
          <br />
          <MainTitleTxtBold>reasonable standards.</MainTitleTxtBold>
        </p>
      </div>

      <div className={`fill-box ${fillBox ? "fill-box-animate" : ""}`}></div>
    </>
  );
};

export default Main;

const MainTitleTxt = styled.span`
  text-align: left;
  font: normal normal 300 42px/63px Poppins;
  letter-spacing: -0.84px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 300 28px/42px Poppins;
    letter-spacing: -0.56px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 300 24px/35px Poppins;
    letter-spacing: -0.48px;
    color: #ffffff;
    opacity: 1;
  }
`;

const MainTitleTxtBold = styled.span`
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal 600 42px/63px Poppins;
  letter-spacing: -0.84px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 600 28px/42px Poppins;
    letter-spacing: -0.56px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: -0.48px;
    color: #ffffff;
    opacity: 1;
  }
`;
