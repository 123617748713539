import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import arrowDowndownloadAnimation from "../assets/Download_Arrow_down.json";
import arrowUpdownloadAnimation from "../assets/Download_Arrow_up.json";
import "./DownloadArrow.css";

const DownloadArrow = ({ downloadHover }) => {
  const containerDownRef = useRef(null);
  const containerUpRef = useRef(null);
  const animationDown = useRef(null);
  const animationUp = useRef(null);

  useEffect(() => {
    animationDown.current = lottie.loadAnimation({
      container: containerDownRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: arrowDowndownloadAnimation,
    });

    return () => {
      animationDown.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (downloadHover) {
      animationDown.current.setDirection(1);
      animationDown.current.goToAndPlay(0, true);
    } else {
      animationDown.current.setDirection(-1);
      animationDown.current.goToAndPlay(
        animationDown.current.totalFrames - 1,
        true
      );
    }
  }, [downloadHover]);

  return (
    <div className='downloadArrowWrapper'>
      <div ref={containerDownRef} />
    </div>
  );
};

export default DownloadArrow;
