import React, { useEffect, useState } from "react";
import XButton from "../../components/XButton";
import { policy } from "../../api/project-request";
import { styled } from "styled-components";

function PolicyModal({ closeModal }) {
  const [data, setData] = useState({ title: "", content: "", status: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await policy();
        setData(response.result.data);
      } catch (error) {
        setData({ title: "서버오류", content: "", status: false });
      }
    };

    fetchData();
  }, []);

  return (
    <PolicyModalWrapper>
      <div className='modalTitleWrapper'>
        {data.title && <p className='modalTitle'>{data.title}</p>}

        <div className='ModalXButton'>
          <img
            src={process.env.PUBLIC_URL + "/img/policyX.png"}
            onClick={closeModal}
          />
        </div>
      </div>
      <div
        className='policyText'
        dangerouslySetInnerHTML={{
          __html: data.content.replace(/”/g, '"'),
        }}
      ></div>
    </PolicyModalWrapper>
  );
}

export default PolicyModal;

const PolicyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1000px;

  .modalTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    text-align: left;
    font: normal normal 600 30px/30px Pretendard;
    letter-spacing: -0.6px;
    color: #ffffff;
    opacity: 1;

    padding-bottom: 24px;

    border-bottom: 1px solid #868686;

    margin-bottom: 42px;

    .ModalXButton {
      width: 36px;
      height: 36px;

      img {
        width: 36px;
        height: 36px;
      }
    }
  }

  .policyText {
    height: 70vh;

    text-align: left;
    font: normal normal 500 18px/30px Pretendard;
    letter-spacing: -0.36px;

    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  @media (max-width: 1850px) {
    min-width: 648px;

    .modalTitleWrapper {
      text-align: left;
      font: normal normal 600 20px/20px Pretendard;
      letter-spacing: -0.4px;
      color: #ffffff;
      opacity: 1;

      padding-bottom: 12px;

      border-bottom: 1px solid #868686;

      margin-bottom: 32px;

      .ModalXButton {
        width: 23px;
        height: 23px;
        img {
          width: 23px;
          height: 23px;
        }
      }
    }

    .policyText {
      height: 70vh;

      text-align: left;
      font: normal normal medium 12px/20px Pretendard;
      letter-spacing: -0.24px;

      overflow: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
    }
  }

  @media (max-width: 786px) {
    min-width: 324px;

    .modalTitleWrapper {
      text-align: left;
      font: normal normal 600 18px/28px Pretendard;
      letter-spacing: -0.36px;
      color: #ffffff;
      opacity: 1;

      padding-bottom: 21px;

      border-bottom: 1px solid #868686;

      margin-bottom: 34px;

      .ModalXButton {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .policyText {
      height: 70vh;

      text-align: left;
      font: normal normal 500 14px/24px Pretendard;
      letter-spacing: -0.28px;

      overflow: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
    }
  }
`;

/* <div className="policyText">
<span>개인정보 수집 • 이용에 대한 동의</span>
<p>
  회원님의 소중한 개인정보는 다음과 같은 정책에 따라 수집 및
  이용됩니다. 바운더리에서는 해당 목적에 연관되는 개인정보만을
  수집하며, 수집된 정보를 투명하고 안전하게 보호 관리할 것을
  약속합니다. 이에 개인정보 수집 및 이용에 대한 동의를 구합니다.{" "}
</p>
<span>개인정보의 수집 • 이용 목적</span>
<p>
  • 회원님의 프로젝트에 대한 견적, 기간. 개발방법 등의 문의에 대한
  정보가 보다 정확한 답변을 위해 수집됩니다.{" "}
</p>
<p>
  • 상시 인력채용을 위한 인재풀 유지를 위해 지원자의 개인정보가
  수집됩니다.{" "}
</p>
<span>수집항목</span>
<p>
  • 필수항목 : 성명, 나이, 회사명, 웹사이트, 연락처, 이메일, 의뢰내용,
  예산, 기간, 첨부파일
</p>
<p>• 선택항목 : 홈페이지</p>
<span>보유이용기간</span>
<p>
  원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
  지체 없이 파기합니다.
</p>
<p>
  단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
  보존합니다.
</p>
<p>• 보존 이유 : 회원님의 동의를 통한 인재정보 유지</p>
<p>• 보존 기간 : 회원정보 삭제 요청시까지</p>
<span>동의를 거부할 권리 및 동의를 거부할 경우의 이익</span>
<p>
  위 개인정보 중 필수적 정보의 수집•이용에 관한 동의는 채용 심사를
  위하여 필수적이므로,{" "}
</p>
<p>위 사항에 동의하셔야만 채용심사가 가능합니다.</p>
<p>
  위 개인정보 중 선택적 정보의 수집•이용에 관한 동의는 거부하실 수
  있으며, 다만 동의하지 않으시는 경우 채용 심사시 불이익을
</p>
<p>
  받으실 수 있으며, 「동의하지 않음」을 선택한 후 본인이 등록한 선택적
  정보에 대해서는 수집 • 이용에 대해 동의한 것으로 간주합니다.
</p>
</div> */
