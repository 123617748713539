import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-web";
import animationData from "../../assets/curationButton.json";
import "./CurationButton.css";
import { useRecoilState } from "recoil";
import {
  curationState,
  fillBoxState,
  showContentState,
} from "../../store/curationAtom";
import { useNavigate } from "react-router-dom";

const CurationButton = ({ onClick }) => {
  const containerRef = useRef(null);
  const animation = useRef(null);
  const navigate = useNavigate();
  const [showContent, setShowContent] = useRecoilState(showContentState); //추가
  const [curationVisible, setCurationVisible] = useRecoilState(curationState);
  const [fillBox, setFillBox] = useRecoilState(fillBoxState);

  useEffect(() => {
    animation.current = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: animationData,
    });

    return () => {
      animation.current.destroy();
    };
  }, []);

  const onMouseEnter = () => {
    animation.current.setDirection(1);
    animation.current.goToAndPlay(0, true);
  };

  const onMouseLeave = () => {
    animation.current.setDirection(-1);
    animation.current.goToAndPlay(animation.current.totalFrames - 1, true);
  };

  const curationClickHandler = () => {
    // setShowContent(false);
    setCurationVisible(true);
    setTimeout(() => {
      setFillBox(true);
    }, 900);
    setTimeout(() => { 
      setFillBox(true);
      navigate("/curation");
      setShowContent(true);
      setCurationVisible(false);
    }, 1800);
  };

  return (
    <div
      className='curationButton'
      ref={containerRef}
      onClick={curationClickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default CurationButton;
