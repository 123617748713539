import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock-upgrade";

const Modal = ({ $isOpen, children, onClose }) => {

  const body = document.querySelector("body");

  useEffect(() => {
    if ($isOpen) {
      disableBodyScroll(body);
    } else {
      // 모달이 닫혔을 때 스크롤 잠금을 해제합니다.
      clearAllBodyScrollLocks();
    }

    // 컴포넌트가 언마운트될 때 잠금을 해제합니다. (선택적)
    return () => clearAllBodyScrollLocks();
  }, [$isOpen]);

  // 모달 컴포넌트가 아직 열리지 않았다면, 아무 것도 렌더링하지 않습니다.
  if (!$isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Backdrop onClick={onClose} />
      <ModalContent>{children}</ModalContent>
    </>,
    document.body // 렌더링할 DOM을 선택합니다. 여기서는 body를 선택했습니다.
  );
};

export default Modal;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.9);

  z-index: 998;
`;

const ModalContent = styled.div`
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 999;
`;
