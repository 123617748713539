import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { keyframes, styled } from "styled-components";
import { navStyleState } from "../../store/navStyleAtom";
import { useEffect, useRef } from "react";

const DesktopMenu = () => {
  const [navStyle, setNavStyle] = useRecoilState(navStyleState);
  const location = useLocation();
  const topline = useRef(null);
  const aboutRef = useRef(null);
  const workRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <DesktopMenuContainer className='desktopMenuContainer'>
      <div>
        <NavLink
          className={
            location.pathname.split("/")[1] === "curation" ? "onCuration" : ""
          }
          to='/about'
          style={{ color: navStyle }}
          ref={aboutRef}
        >
          About
        </NavLink>
      </div>
      <div>
        <NavLink
          to='/work'
          style={{ color: navStyle }}
          ref={workRef}
          className={
            `${location.pathname.split("/")[1] === "curation" ? "onCuration" : ""} worknav`
          }
        >
          Work
        </NavLink>
      </div>
      <div>
        <NavLink
          to='/contact'
          style={{ color: navStyle }}
          ref={contactRef}
          className={
            location.pathname.split("/")[1] === "curation" ? "onCuration" : ""
          }
        >
          Contact
        </NavLink>
      </div>
    </DesktopMenuContainer>
  );
};

export default DesktopMenu;

const fadein = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DesktopMenuContainer = styled.div`
  position: relative;

  display: none;

  margin-right: 28px;
  @media (min-width: 768px) {
    display: flex;
    animation: ${fadein} 0.5s;
  }

  div {
    width: auto;
    margin-left: 68px;
  }

  a {
    position: relative;

    &.onCuration {
      text-align: left;
      font: normal normal 600 20px/30px Poppins;
      letter-spacing: -0.2px;
      color: #000000;
      opacity: 1;

      @media (max-width: 1850px) {
        text-align: left;
        font: normal normal 600 14px/21px Poppins;
        letter-spacing: -0.14px;
        color: #000000;
        opacity: 1;
      }
    }
    text-align: left;
    font: normal normal 300 20px/30px Poppins;
    letter-spacing: -0.2px;
    color: #000000;
    opacity: 1;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      right: 50%;
      width: 0%;
      border-top: 2px solid transparent;

      transition: 0.5s all;
    }
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: 50%;
      width: 0%;
      border-top: 2px solid transparent;

      transition: 0.5s all;
    }

    &.active {
      &::before {
        width: 50%;
        border-top: 2px solid #ffffff;
      }
      &::after {
        width: 50%;
        border-top: 2px solid #ffffff;
      }
      &.worknav {
        &::before {
        width: calc(50% + 1px);
        border-top: 2px solid #ffffff;
      }
      }
    }

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 300 14px/21px Poppins;
      letter-spacing: -0.14px;
      color: #000000;
      opacity: 1;
    }

    &.active {
      text-align: left;
      font: normal normal 600 20px/30px Poppins;
      letter-spacing: -0.2px;
      color: #000000;
      opacity: 1;

      @media (max-width: 1850px) {
        text-align: left;
        font: normal normal 600 14px/21px Poppins;
        letter-spacing: -0.14px;
        color: #000000;
        opacity: 1;
      }
    }
  }
`;

const TopLine = styled.div`
  position: absolute;

  transition: all 0.5s;

  border-top: 3px solid white;
`;
