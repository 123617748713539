const ToTopButton = () => {
  return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='30'
        height='65'
        viewBox='0 0 30 65'
      >
        <g
          id='그룹_23675'
          data-name='그룹 23675'
          transform='translate(-1157 -987)'
        >
          <text
            id='Top'
            transform='translate(1172 1046)'
            fill='#fff'
            fontSize='16'
            fontWeight='600'
            letterSpacing='-0.01em'
          >
            <tspan x='-14.984' y='0'>
              Top
            </tspan>
          </text>
          <path
            id='패스_10123'
            data-name='패스 10123'
            d='M7,0,0,6.789,2.1,8.828,5.512,5.521V28H8.487V5.521L11.9,8.828,14,6.789Z'
            transform='translate(1165 987)'
            fill='#fff'
          />
        </g>
      </svg>
  );
};

export default ToTopButton