import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { requestStatus } from "../../api/project-request";
import { useRecoilState } from "recoil";
import { requestFile, requestState } from "../../store/requestAtom";

const RequestComponent2 = () => {
  const [requestRecoilState, setRequestState] = useRecoilState(requestState);
  const [requestInputFile, setRequestInputFile] = useRecoilState(requestFile);
  const [isFocused, setIsFocused] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const response = await requestStatus();
      if (response.isSuccess) {
        setItems(response.result);
      }
    };
    fetchItems();
  }, []);

  const changeHandler = (item) => {
    if (requestRecoilState.projectPreparationStatus.includes(item)) {
      setRequestState((prev) => {
        return {
          ...prev,
          projectPreparationStatus: prev.projectPreparationStatus.filter(
            (previtem) => previtem !== item
          ),
        };
      });
    } else {
      setRequestState((prev) => {
        return {
          ...prev,
          projectPreparationStatus: [...prev.projectPreparationStatus, item],
        };
      });
    }
  };

  const FileHandler = (event) => {
    const file = event.target.files[0];
    setRequestInputFile(file);
    event.target.value = null;
  };

  const CheckBoxes = items.map((item, idx) => {
    return (
      <div key={item + idx}>
        <input
          type='checkbox'
          className='check-list-input'
          name={"check" + idx}
          checked={requestRecoilState.projectPreparationStatus.includes(item)}
          onChange={() => changeHandler(item)}
          id={"check" + idx}
        />
        <label className='check-list-label' htmlFor={"check" + idx}>
          {item}
        </label>
      </div>
    );
  });

  return (
    <InputWrapper>
      <div className='first-column'>
        <InputNameWrapper>
          <label htmlFor='project-name' className='input-title'>
            프로젝트 이름
          </label>
          <input
            name='project-name'
            id='project-name'
            placeholder='프로젝트 이름이 무엇인가요?'
            onChange={(event) =>
              setRequestState((prev) => {
                return { ...prev, projectName: event.target.value };
              })
            }
            value={requestRecoilState.projectName}
            autoComplete='off'
          />
        </InputNameWrapper>
        <InputCheckWrapper>
          <label className='input-title haveSubtitle'>프로젝트 준비 현황</label>
          <span className='input-subtitle'>
            프로젝트 진행을 위해 준비되어 있는 자료를 선택해주세요.
          </span>
          <div className='check-list-box'>{CheckBoxes}</div>
        </InputCheckWrapper>
      </div>
      <div className='second-column'>
        <InputDetailWrapper isFocused={isFocused}>
          <div className='textarea-header'>
            <label className='input-title'>프로젝트 상세 내용</label>
            <div className='length-div'>
              <span className='length'>
                {requestRecoilState.projectDetail.length}
              </span>
              <span className='total-length'>/1000</span>
            </div>
          </div>
          <div className='textarea-box'>
            <textarea
              placeholder='상세 내용을 입력해주세요.'
              onChange={(event) =>
                setRequestState((prev) => {
                  return { ...prev, projectDetail: event.target.value };
                })
              }
              value={requestRecoilState.projectDetail}
              maxLength={1000}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              autoComplete='off'
            />
          </div>
        </InputDetailWrapper>
        <InputFileWrapper className={requestInputFile ? "isfile" : ""}>
          <div className='file-input'>
            <label htmlFor='fileInput'>
              참고파일 업로드
              <img
                className='contactPlus'
                src={process.env.PUBLIC_URL + "/img/contactPlus.svg"}
              />
            </label>
            <input
              id='fileInput'
              type='file'
              accept='*.*'
              onChange={FileHandler}
            />
          </div>
          <div className='file-name'>
            {requestInputFile ? (
              <>
                <p className='file-txt'>{requestInputFile.name}</p>
                <img
                  src={process.env.PUBLIC_URL + "/img/contactX.svg"}
                  onClick={() => setRequestInputFile(null)}
                />
              </>
            ) : (
              <p className='nonefile-txt'>
                참고할 만한 자료가 있다면 첨부해주세요.
              </p>
            )}
          </div>
        </InputFileWrapper>
      </div>
    </InputWrapper>
  );
};

export default RequestComponent2;

// @media (max-width: 1850px) {
//   margin-bottom: 66px;
// }

// @media (max-width: 786px) {
//   margin-bottom: 44px;
// }

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 604px;
    @media (max-width: 1850px) {
      width: 393px;
    }

    @media (max-width: 786px) {
      width: 100%;
      padding: 0px 18px;
    }
  }

  .first-column {
    margin-right: 98px;
    @media (max-width: 1850px) {
      margin-right: 66px;
    }

    @media (max-width: 786px) {
      margin-right: 0px;
    }
  }

  .input-title {
    text-align: left;
    font: normal normal 500 20px/56px Pretendard;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 1;

    &.haveSubtitle {
      font: normal normal 500 20px/40px Pretendard;

      @media (max-width: 1850px) {
        font: normal normal 500 14px/30px Pretendard;
      }

      @media (max-width: 786px) {
        text-align: left;
        font: normal normal 500 16px/16px Pretendard;
        margin-bottom: 8px;
      }
    }

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 500 14px/56px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 16px/16px Pretendard;
      letter-spacing: -0.32px;
      color: #ffffff;
      opacity: 1;
      margin-bottom: 12px;
    }
  }

  .input-subtitle {
    margin-bottom: 32px;

    text-align: left;
    font: normal normal normal 16px/16px Pretendard;
    letter-spacing: -0.32px;
    color: #707070;
    opacity: 1;
    @media (max-width: 1850px) {
      margin-bottom: 20px;

      text-align: left;
      font: normal normal 500 12px/12px Pretendard;
      letter-spacing: -0.24px;
      color: #707070;
      opacity: 1;
    }

    @media (max-width: 786px) {
      margin-bottom: 24px;

      text-align: left;
      font: normal normal 500 12px/12px Pretendard;
      letter-spacing: -0.24px;
      color: #707070;
      opacity: 1;
    }
  }
`;

const InputNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media (max-width: 1850px) {
    margin-bottom: 35px;
  }

  @media (max-width: 786px) {
    margin-bottom: 36px;
  }

  input {
    height: 48px;

    padding: 0px 12px;

    text-align: left;
    font: normal normal normal 16px/56px Pretendard;
    /* letter-spacing: -0.32px; */
    color: #ffffff;
    opacity: 1;

    border: 1px solid #707070;
    background-color: transparent;

    &:focus-visible {
      outline: 1px solid #ffffff;
    }

    @media (max-width: 1850px) {
      height: 36px;

      text-align: left;
      font: normal normal normal 14px/14px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      height: 48px;
      width: 324px;

      text-align: left;
      font: normal normal normal 14px/14px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;

const InputCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .check-list-box {
    display: grid;
    grid-template-columns: repeat(4, 121px);
    grid-column-gap: 35px;
    grid-row-gap: 24px;

    @media (max-width: 1850px) {
      grid-template-columns: repeat(4, 90px);
      grid-column-gap: 12px;
      grid-row-gap: 16px;
    }

    @media (max-width: 786px) {
      grid-template-columns: repeat(2, 110px);
      grid-column-gap: 54px;
      grid-row-gap: 16px;

      margin-bottom: 36px;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .check-list-input {
    width: 24px;
    height: 24px;
    margin: 0;
    margin-right: 12px;

    background: #ffffff00;
    border: 1px solid #707070;
    cursor: none;
    appearance: none;

    &:checked {
      border: 1px solid #fff;
      background-color: #fff;
      background-image: url(${process.env.PUBLIC_URL + "/img/check.svg"});
      background-size: cover;
    }

    @media (max-width: 1850px) {
      width: 18px;
      height: 18px;

      margin-right: 8px;
    }

    @media (max-width: 786px) {
      width: 20px;
      height: 20px;

      margin-right: 12px;
    }
  }

  .check-list-label {
    text-align: left;
    font: normal normal normal 16px/30px Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal normal 12px/30px Pretendard;
      letter-spacing: -0.24px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal normal 14px/14px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;

const InputDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  .textarea-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .length {
      text-align: left;
      font: normal normal 500 16px/56px Pretendard;
      letter-spacing: -0.32px;
      color: #ffffff;
      opacity: 1;
    }

    .length-div {
      margin-bottom: 0px;
    }

    .total-length {
      text-align: left;
      font: normal normal 500 16px/56px Pretendard;
      letter-spacing: -0.32px;
      color: #585858;
      opacity: 1;
    }
    @media (max-width: 1850px) {
      .length {
        text-align: left;
        font: normal normal 500 12px/56px Pretendard;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }

      .total-length {
        text-align: left;
        font: normal normal 500 12px/56px Pretendard;
        letter-spacing: -0.24px;
        color: #585858;
        opacity: 1;
      }
    }

    @media (max-width: 786px) {
      width: 324px;

      .length {
        text-align: right;
        font: normal normal 500 12px/16px Pretendard;
        letter-spacing: -0.24px;
        color: #ffffff;
        opacity: 1;
      }

      .total-length {
        text-align: right;
        font: normal normal 500 12px/16px Pretendard;
        letter-spacing: -0.24px;
        color: #585858;
        opacity: 1;
      }

      .length-div {
        margin-bottom: 12px;
      }
    }
  }

  .textarea-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 294px;

    border: 1px solid ${(props) => (props.isFocused ? "#ffffff" : "#707070")};
    background-color: transparent;

    overflow: hidden;

    @media (max-width: 1850px) {
      width: 393px;
      height: 160px;
    }

    @media (max-width: 786px) {
      width: 324px;
      height: 200px;
    }
  }

  textarea {
    width: 572px;
    height: 260px;

    border: none;

    background-color: transparent;

    /* margin: 16px 12px 18px 12px; */

    text-align: left;
    font: normal normal normal 16px/28px Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    overflow: auto;

    @media (max-width: 1850px) {
      width: 360px;
      height: 138px;
      text-align: left;
      font: normal normal normal 14px/24px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      width: 292px;
      height: 172px;
      text-align: left;
      font: normal normal normal 14px/24px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    resize: none;

    &:focus {
      outline: none; /* 포커스 시 테두리 없애기 */
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
`;

const InputFileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 153px;

  @media (max-width: 1850px) {
    margin-bottom: 131px;
  }

  @media (max-width: 786px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
  }

  &.isfile {
    justify-content: space-between;

    .file-name {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 400px;
      height: auto;

      padding: 8px 16px 8px 16px;

      border: 1px solid #707070;

      img {
        width: 15px;
        height: 15px;
      }

      @media (max-width: 1850px) {
        width: 240px;

        padding: 8px 16px 8px 16px;

        height: auto;
      }

      @media (max-width: 786px) {
        width: 324px;

        padding: 16px 16px 16px 16px;

        margin-top: 20px;
      }
    }
  }

  .file-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 140px;

    text-align: left;
    font: normal normal 500 16px/24px Pretendard;
    letter-spacing: -0.32px;
    color: #ffffff;
    opacity: 1;

    margin-right: 20px;

    label {
      display: flex;
      align-items: center;

      border-bottom: 1px solid #fff;

      &:hover {
        img {
          transform: rotate(180deg);
        }
      }
    }

    img {
      width: 26px;
      height: 26px;

      margin-left: 8px;

      transition: 0.5s all;
    }

    input {
      display: none;
    }

    @media (max-width: 1850px) {
      width: 140px;
      text-align: left;
      font: normal normal 500 14px/24px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;

      margin-right: 20px;

      img {
        width: 22px;
        height: 22px;
      }
    }

    @media (max-width: 786px) {
      width: 150px;
      text-align: left;
      font: normal normal 500 16px/24px Pretendard;
      letter-spacing: -0.32px;
      color: #ffffff;
      opacity: 1;

      margin-right: 16px;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  .file-name {
    width: auto;
  }
  .isfile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #707070;
  }
  .file-txt {
    text-align: left;
    font: normal normal 500 14px/20px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;

    @media (max-width: 1850px) {
      font: normal normal 500 14px/20px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 14px/20px Pretendard;
      letter-spacing: -0.28px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .nonefile-txt {
    margin-top: 8px;
    text-align: left;
    font: normal normal 500 16px/20px Pretendard;
    letter-spacing: -0.32px;
    color: #868686;
    opacity: 1;

    @media (max-width: 1850px) {
      text-align: left;
      font: normal normal 500 12px/20px Pretendard;
      letter-spacing: -0.24px;
      color: #707070;
      opacity: 1;
    }

    @media (max-width: 786px) {
      text-align: left;
      font: normal normal 500 12px/20px Pretendard;
      letter-spacing: -0.24px;
      color: #707070;
      opacity: 1;
    }
  }
`;
