import React, { useState, useEffect } from "react";
import "./Contact.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import PolicyModal from "../components/Modeal/ContactModal";
import MobileMenuButton from "../components/Header/MobileMenu";
import AOS from "aos";
import "aos/dist/aos.css";
import DownloadArrow from "../components/DownloadArrow";
import { companyProfile } from "../api/project-request";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { ContactAtom } from "../store/modalAtom";
import Modal from "../components/Modeal/Modal";
import ContactModal from "../components/Modeal/ContactModal";

const Contact = () => {
  const { menu } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fileUrl, setFileUrl] = useState(null); // 추가된 부분

  const [isContactModalOpen, setIsContactModalOpen] =
    useRecoilState(ContactAtom);
  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  const [downloadHover, setDownloadHover] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);
  const handleDownload = async () => {
    const response = await companyProfile();
    if (response.isSuccess) {
      const url = response.result.fileUrl;
      const name = response.result.fileName;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const encodedUrl = reader.result;
            setFileUrl(encodedUrl);
          };
          reader.readAsDataURL(blob);
          window.open(url);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    }
  };

  return (
    <ContactWrapper>
      <div
        className='contactTextWrapper'
        data-aos='fade-up'
        data-aos-duration='1000'
      >
        <ContractTitleTxt>Do you wanna make</ContractTitleTxt>
        <br></br>
        <ContractTitleBoldTxt>a fun experience </ContractTitleBoldTxt>
        <ContractTitleTxt>with us?</ContractTitleTxt>
      </div>
      <div className='request'>
        <p>
          <ContactSubTxt>
            바운더리는 새로운 관계를 형성하고, 함께 성장할
            <br className='mobilebr' /> 파트너를 기다리고 있습니다.
            <br className='desktopbr' />
            파트너십 문의나 프로젝트 <br className='mobilebr' />
            의뢰를 보내주시면 워킹데이 3일 이내에 회신드립니다.
          </ContactSubTxt>
        </p>
        <Link to='/contact/request/1' style={{ textDecoration: "none" }}>
          <ContactBtnTxt className='requestButton'>
            프로젝트 의뢰
            <img
              className='blackArrow'
              src={process.env.PUBLIC_URL + "/img/Black_Arrow.svg"}
            />
          </ContactBtnTxt>
        </Link>
      </div>
      <div
        className='downloadWrapper'
        data-aos='fade-down'
        data-aos-duration='1000'
        data-aos-delay='0'
        data-aos-offset='0'
      >
        <button
          className='downloadButton'
          onClick={handleDownload}
          onMouseEnter={() => setDownloadHover(true)}
          onMouseLeave={() => setDownloadHover(false)}
        >
          <div className='download2Div'>
            <ContactDownKrTxt>회사소개서</ContactDownKrTxt>
            <ContactDownEnTxt> Download</ContactDownEnTxt>
          </div>
          <DownloadArrow downloadHover={downloadHover} />
        </button>
      </div>
      <Modal $isOpen={isContactModalOpen} onClose={closeContactModal}>
        <ContactModal closeModal={closeContactModal} />
      </Modal>
    </ContactWrapper>
  );
};

export default Contact;

const ContactWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;

  svg {
    width: 24px !important;
    height: 24px !important;

    @media (max-width: 1850px) {
      width: 20px !important;
      height: 20px !important;
    }

    @media (max-width: 768px) {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

const ContractTitleTxt = styled.span`
  text-align: left;
  font: normal normal 300 80px/98px Poppins;
  letter-spacing: -1.6px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 300 48px/48px Poppins;
    letter-spacing: -0.96px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 300 24px/35px Poppins;
    letter-spacing: -0.48px;
    color: #ffffff;
    opacity: 1;
  }
`;

const ContractTitleBoldTxt = styled.span`
  text-align: left;
  font: normal normal 300 80px/98px Poppins;
  font-weight: bold;
  font-style: italic;
  letter-spacing: -1.6px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 300 48px/98px Poppins;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.96px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 300 24px/35px Poppins;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.48px;
    color: #ffffff;
    opacity: 1;
  }
`;

const ContactSubTxt = styled.span`
  text-align: left;
  font: normal normal 300 20px/30px Pretendard;
  letter-spacing: -0.4px;
  color: #ffffff;
  opacity: 1;

  .mobilebr {
    display: none;
  }
  .desktopbr {
    display: inherit;
  }

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal 300 14px/26px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
    .mobilebr {
      display: none;
    }
    .desktopbr {
      display: inherit;
    }
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal 300 14px/22px Pretendard;
    letter-spacing: -0.28px;
    color: #868686;
    opacity: 1;

    .mobilebr {
      display: inherit;
    }
    .desktopbr {
      display: none;
    }
  }
`;

const ContactBtnTxt = styled.button`
  text-align: center;
  font: normal normal bold 20px/56px Pretendard;
  letter-spacing: -0.4px;
  color: #000000;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: center;
    font: normal normal bold 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: center;
    font: normal normal bold 14px/56px Pretendard;
    letter-spacing: -0.28px;
    color: #000000;
    opacity: 1;
  }
`;

const ContactDownKrTxt = styled.span`
  text-align: left;
  font: normal normal bold 20px/42px Pretendard;
  letter-spacing: -0.4px;
  color: #ffffff;
  opacity: 1;
  margin-right: 10px;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal bold 14px/42px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal bold 14px/42px Pretendard;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }
`;

const ContactDownEnTxt = styled.span`
  text-align: left;
  font: normal normal normal 20px/42px Poppins;
  letter-spacing: -0.4px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 1850px) {
    text-align: left;
    font: normal normal normal 14px/42px Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    text-align: left;
    font: normal normal normal 14px/42px Poppins;
    letter-spacing: -0.28px;
    color: #ffffff;
    opacity: 1;
  }
`;
