import { Route, Routes, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import RequestComponent1 from "../components/requests/Request_1";
import RequestComponent4 from "../components/requests/Request_4";
import RequestComponent3 from "../components/requests/Request_3";
import RequestComponent2 from "../components/requests/Request_2";
import { useRecoilState } from "recoil";
import RequestHeader from "../components/requests/RequestHeader";
import MobileRequestNav from "../components/requests/RequestMobileHeader";
import RequestSideBar from "../components/requests/RequestSideBar";
import Modal from "../components/Modeal/Modal";
import PolicyModal from "../components/Modeal/PolicyModal";
import { InfoAtom } from "../store/modalAtom";

const RequestPage = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useRecoilState(InfoAtom);

  const openInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <>
      <RequesetWrapper>
        <RequestHeader />
        <RequestContentWrapper>
          <RequestSideBar />
          <div className='main-contents'>
            <Routes>
              <Route path='/1' element={<RequestComponent1 />} />
              <Route path='/2' element={<RequestComponent2 />} />
              <Route path='/3' element={<RequestComponent3 />} />
              <Route path='/4' element={<RequestComponent4 />} />
            </Routes>
          </div>
        </RequestContentWrapper>
        <MobileRequestNav />
      </RequesetWrapper>
      <Modal $isOpen={isInfoModalOpen} onClose={closeInfoModal}>
        <PolicyModal closeModal={closeInfoModal} />
      </Modal>
    </>
  );
};

export default RequestPage;

const RequesetWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 300px;

  @media (max-width: 1850px) {
    margin-top: 156px;
  }
  @media (max-width: 786px) {
    margin-top: 110px;
  }
`;

const RequestContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  color: white;
  @media (max-width: 786px) {
    justify-content: center;
  }
`;
