import { atom } from "recoil";

const curationState = atom({
  key: "curationState",
  default: false,
});

const showContentState = atom({
  key: "showContentState",
  default: true,
});

const fillBoxState = atom({
  key: "fillBoxState",
  default: false,
});

export { curationState, showContentState, fillBoxState };
