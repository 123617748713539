import "./App.css";
import Header from "./components/Header/Header";
import Spinner from "../src/components/Spinner";
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Main from "./pages/Main";
import About from "./pages/About";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import Curation from "./pages/Curation";
import Detail from "./pages/Detail";
import { background } from "../src/api/project-request";
import PDFViewer from "./components/PDFPreview";
import Footer from "./components/Footer/Footer";
import { useRecoilState } from "recoil";
import {
  curationState,
  fillBoxState,
  showContentState,
} from "./store/curationAtom";
import RequestPage from "./pages/Request";
import { isLoadingState } from "./store/isLoading";
import BlurAreaPortal from "./components/Cursor";
import GlobalStyle from "./style/globalstyle";

function App() {
  const [loading, setLoading] = useRecoilState(isLoadingState);
  const location = useLocation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showContent, setShowContent] = useRecoilState(showContentState); //추가
  const [curationVisible, setCurationVisible] = useRecoilState(curationState);
  const [fillBox, setFillBox] = useRecoilState(fillBoxState);
  const [backgrounds, setBackgrounds] = useState([{fileUrl: ""}]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  

  const navigate = useNavigate();
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const screenWidth = window.innerWidth;
      let backgroundType;

      // 화면 가로 길이에 따라 backgroundType 결정
      if (screenWidth >= 1281) {
        backgroundType = "MONITOR";
      } else if (screenWidth >= 480) {
        backgroundType = "TABLET";
      } else {
        backgroundType = "MOBILE";
      }

      const response = await background(backgroundType);
      console.log(response);
      if (response.isSuccess) {
        const backgroundsWithIndex = response.result.map((bg, index) => ({
          ...bg,
          index,
        }));
        setBackgrounds(backgroundsWithIndex);
      } else {
        console.log("Failed to fetch backgrounds:");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleMouseLeave = () => {
      setMousePosition({ x: 0, y: 0 });
    };

    window.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const handleVideoEnded = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      fetchData()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("연결이 원활하지 않습니다");
        });
    } else {
      setLoading(false);
    }
  }, [location.pathname]);

  const fetchData = () => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        resolve();
      }, 1500); // 1.5초
    });
  };

  useEffect(() => {
    setShowContent(false);
    if (
      location.pathname === "/work" ||
      location.pathname === "/curation" ||
      location.pathname.startsWith("/work/detail") ||
      location.pathname.startsWith("/pdf-viewer")
    ) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <GlobalStyle />
      <BlurAreaPortal />
      <div className='App'>
        <Header />
        <main>
          <div>{loading ? <Spinner /> : null}</div>

          {showContent && ( // showContent가 true일 때만 video-background 렌더링
            <div className='video-background'>
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  src={backgrounds[currentVideoIndex].fileUrl}
                  onEnded={handleVideoEnded}
                  style={{
                    top: 0,
                    left: 0,
                    // display: bg.index === currentVideoIndex ? "block" : "none",
                    zIndex: -1,
                  }}
                  className='video-element'
                >
                </video>

              {curationVisible && (
                <div
                  className={`fill-box ${fillBox ? "fill-box-animate" : ""}`}
                ></div>
              )}
            </div>
          )}
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/about' element={<About />} />
            <Route path='/work' element={<Work />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/curation' element={<Curation />} />
            <Route path='/work/detail/:id' element={<Detail />} />
            <Route path='/pdf-viewer' element={<PDFViewer />} />
            {/* <Route path='/contact/request/act' element={<RequestModule />} /> */}
            <Route path='/contact/request/*' element={<RequestPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default App;
