import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Header.css";
import NavBar from "./NavBar";
import { styled } from "styled-components";
import Logo from '../../assets/logo.png';

const Header = ({}) => {
  const { menu } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <HeaderWrapper className='headerWrapper'>
        <span
          className='logo'
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={Logo} style={{width: 120}} />
        </span>
        <NavBar />
      </HeaderWrapper>
    </>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;

  margin: 30px 34px;

  width: calc(100% - 68px);
  height: 60px;

  background-color: transparent;

  user-select: none;

  @media (max-width: 1850px) {
    margin: 20px 36px;

    width: calc(100% - 72px);
    height: 60px;
  }

  @media (max-width: 786px) {
    margin: 20px 16px;

    width: calc(100% - 32px);
    height: 40px;
  }
`;
